import React, {useEffect} from 'react';
import EditProcessContainer from "../../../containers/dialogs/editProcess/EditProcessContainer";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from "@material-ui/core";
import _, {cloneDeep} from "lodash";
// import {useWhatChanged} from "@simbathesailor/use-what-changed";

const truncate = (str) => {
  return str.length > 70 ? str.slice(0, 70).concat("...") : str
}

function updateGroupMixQty(mix, groupFlows, temp) {

  let signedQtySum = 0
  groupFlows.forEach(e => {
    if (!e.removed) {
      signedQtySum += (e.quantity * e.quantityFactor)
    }
  })
  const selectedMix = temp.mixes.find(e => e.id === mix.id)
  selectedMix.quantity = Math.abs(signedQtySum)
  selectedMix.quantityFactor = signedQtySum >= 0 ? 1 : -1

  return temp
}

function updateMixFlowsQty(mix, groupFlows, temp) {
  let qtyContributeSum = 0
  groupFlows.forEach(e => {
    qtyContributeSum += e.quantity
  })
  groupFlows.forEach(e => {
    if (!e.removed) {
      if (qtyContributeSum === 0) {
        e.quantity = 0
      } else {
        e.quantity = e.quantity * (mix.quantity / qtyContributeSum)
      }
      const selectedFlow = temp.flows.find(elem => elem.id === e.id)
      selectedFlow.quantity = e.quantity
      selectedFlow.customized = true
    }
  })
  return temp
}


const EditProcessModalComponent = ({
                                     onClose,
                                     open,
                                     selectedProcessId,
                                     productionLine,
                                     modifyProcess,
                                     showAlert,
                                     deleteAssessments
                                   }) => {
  const [selectedProcess, setSelectedProcess] = React.useState()
  const [selectedToggle, setSelectedToggle] = React.useState("Input")

  // useWhatChanged([selectedProcess,selectedToggle])

  useEffect(() => {
    let processFiltered = productionLine.processes.find(x => {
      return x.id === selectedProcessId
    })
    if (processFiltered !== undefined) {
      let processFilteredClone = _.cloneDeep(processFiltered);
      setSelectedProcess(processFilteredClone)
    }
  }, [selectedProcessId, open]);

  const handleSave = () => {
    onClose();
  }

  const handleRemoveFlow = (flowId, isElementary) => {
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)
    temp.customized = true

    if (!isElementary) {
      const flow = temp.flows.find(e => e.id === flowId)

      flow.customized = true
      flow.removed = true

      if ("mixId" in flow) {
        const mix = temp.mixes.find(e => e.id === flow.mixId)
        const groupFlows = temp.flows.filter(e => e.mixId === flow.mixId)
        temp = updateGroupMixQty(mix, groupFlows, temp)
      }
    }

    if (isElementary) {
      const elementaryFlow = temp.elementaryFlows.find(e => e.id === flowId)
      elementaryFlow.customized = true;
      elementaryFlow.removed = true
    }

    setSelectedProcess(temp)
  }

  const handleRestoreFlow = (flowId, isElementary) => {
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)

    if (!isElementary) {
      const flow = temp.flows.find(e => e.id === flowId)

      flow.customized = true
      flow.removed = false

      if ("mixId" in flow) {
        const mix = temp.mixes.find(e => e.id === flow.mixId)
        const groupFlows = temp.flows.filter(e => e.mixId === flow.mixId)
        temp = updateGroupMixQty(mix, groupFlows, temp)
      }
    }

    if (isElementary) {
      const elementaryFlow = temp.elementaryFlows.find(e => e.id === flowId)
      elementaryFlow.customized = true;
      elementaryFlow.removed = false
    }

    setSelectedProcess(temp)
  }

  const handleRestoreMixQty = (mixId, groupFlows) => {
    //console.log("****** handleRestoreMixQty ******")
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)
    const mix = temp.mixes.find(e => e.id === mixId)
    mix.quantity = Math.abs(mix.originalQuantity);
    groupFlows.forEach(e => {
        const selFlow = temp.flows.find(elem => elem.id === e.id)
        selFlow.quantity = Math.abs(selFlow.originalQuantity)
        // console.log(selFlow)
      }
    )
    setSelectedProcess(temp)
  }


  const handleRestoreFlowQty = (flowId, isElementary) => {
    //console.log("****** handleRestoreFlowQty ******")
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)

    if (!isElementary) {
      const flow = temp.flows.find(e => e.id === flowId)
      flow.quantity = Math.abs(flow.originalQuantity);
      if (flow.mixName) {
        const mix = temp.mixes.find(e => e.name === flow.mixName)
        const groupFlows = temp.flows.filter(e => e.flowDirection === "INPUT" && e.mixName && e.mixName === "Electricity mix")
        temp = updateGroupMixQty(mix, groupFlows, temp)
      }
    }

    if (isElementary) {
      const elementaryFlow = temp.elementaryFlows.find(e => e.id === flowId)
      elementaryFlow.quantity = Math.abs(elementaryFlow.originalQuantity);
      elementaryFlow.quantityFactor = elementaryFlow.originalQuantity === 0 ? 1 : Math.sign(elementaryFlow.originalQuantity)
    }

    setSelectedProcess(temp)
  }

  const handleOnBlurMixQuantity = (oldQuantityFactor, newQuantityFactor, value, mixId, groupFlows) => {
    //console.log("****** handleOnBlurMixQuantity ******")
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)
    const mix = temp.mixes.find(e => e.id === mixId)
    mix.quantity = mix.quantity === "" ? 0 : parseFloat(mix.quantity)
    if (oldQuantityFactor !== newQuantityFactor && parseFloat(mix.quantity) !== 0 && mix.quantity !== "") {

      showAlert({
        severity: "success",
        message: "It's not possible to invert the quantity sign for this type of mix",
        autoHideDuration: 3000
      })
    }
    temp = updateMixFlowsQty(mix, groupFlows, temp)
    setSelectedProcess(temp)
  }

  const handleOnChangeMixQuantity = (oldQuantityFactor, newQuantityFactor, value, mixId) => {
    //console.log("****** handleOnChangeMixQuantity ******")
    deleteAssessments();
    let temp = cloneDeep(selectedProcess)
    const mix = temp.mixes.find(e => e.id === mixId)
    mix.quantity = value.replace(/^-/, '');
    if (oldQuantityFactor !== newQuantityFactor && parseFloat(mix.quantity) !== 0 && mix.quantity !== "") {
      showAlert({
        severity: "success",
        message: "It's not possible to invert the quantity sign for this type of mix",
        autoHideDuration: 3000
      })
    }else{
      temp.customized = true
    }
    setSelectedProcess(temp)
  }


  const handleOnBlurQuantity = (oldQuantityFactor, newQuantityFactor, value, flowId, isReferenceFlow, isElementary) => {
    //console.log("****** handleOnBlurQuantity ******")
    deleteAssessments();
    const temp = cloneDeep(selectedProcess)

    if (!isReferenceFlow) {
      temp.customized = true
    }

    if (!isElementary) {
      const flow = temp.flows.find(e => e.id === flowId)
      flow.quantity = flow.quantity === "" ? 0 : parseFloat(flow.quantity)


      if (!flow.removed && flow.flowDirection === "INPUT" && flow.mixName && flow.mixName === "Electricity mix") {
        let signedQuantityMix = 0.0;
        temp.flows.forEach(e => {
            if (e.flowDirection === "INPUT" && e.mixName && e.mixName === "Electricity mix") {
              signedQuantityMix += (e.quantity * e.quantityFactor)
            }
          }
        )
        signedQuantityMix >= 0 ? temp.mixes[0].quantityFactor = 1 : temp.mixes[0].quantityFactor = -1
        temp.mixes[0].quantity = Math.abs(signedQuantityMix)
      }

    }

    if (isElementary) {
      const elementaryFlow = temp.elementaryFlows.find(e => e.id === flowId)
      elementaryFlow.quantity = elementaryFlow.quantity === "" ? 0 : parseFloat(elementaryFlow.quantity)
      elementaryFlow.quantityFactor = elementaryFlow.quantity === "" ? 1 : newQuantityFactor
    }
    setSelectedProcess(temp)
  }

  const handleOnChangeQuantity = (oldQuantityFactor, newQuantityFactor, value, flowId, isReferenceFlow, isElementary) => {
    //console.log("****** handleOnChangeQuantity ******")
    deleteAssessments();
    const temp = cloneDeep(selectedProcess)
    if (!isReferenceFlow) {
      temp.customized = true
    }

    if (!isElementary) {
      const flow = temp.flows.find(e => e.id === flowId)
      flow.quantity = value.replace(/^-/, '');
      flow.customized = true
      if (oldQuantityFactor !== newQuantityFactor && parseFloat(flow.quantity) !== 0 && flow.quantity !== "") {
        showAlert({
          severity: "success",
          message: "It's not possible to invert the quantity sign for this type of flow",
          autoHideDuration: 3000
        })
      }

      if (!flow.removed && flow.flowDirection === "INPUT" && flow.mixName && flow.mixName === "Electricity mix") {
        let signedQuantityMix = 0.0;
        temp.flows.forEach(e => {
            if (e.flowDirection === "INPUT" && e.mixName && e.mixName === "Electricity mix") {
              signedQuantityMix += (e.quantity * e.quantityFactor)
            }
          }
        )
        signedQuantityMix >= 0 ? temp.mixes[0].quantityFactor = 1 : temp.mixes[0].quantityFactor = -1
        temp.mixes[0].quantity = Math.abs(signedQuantityMix)
      }
    }

    if (isElementary) {
      const elementaryFlow = temp.elementaryFlows.find(e => e.id === flowId)
      elementaryFlow.quantity = value.replace(/^-/, '');
      elementaryFlow.customized = true
      elementaryFlow.quantityFactor = newQuantityFactor
    }

    setSelectedProcess(temp)
  };

  const handleSelectedToggle = (value) => {
    setSelectedToggle(value);
  }

  const handleAddMixFlow = (flow) => {
    deleteAssessments();
    const selectedProcessCloned = cloneDeep(selectedProcess)
    flow.mixId = selectedProcessCloned.mixes.find(e => e.mixBaseName===flow.mixName).id
    selectedProcessCloned.flows.unshift(flow)
    selectedProcessCloned.customized = true
    setSelectedProcess(selectedProcessCloned)
  };

  const handleAddFlow = (flow) => {
    deleteAssessments();
    const selectedProcessCloned = cloneDeep(selectedProcess)
    selectedProcessCloned.flows.unshift(flow)
    selectedProcessCloned.customized = true
    setSelectedProcess(selectedProcessCloned)
  };

  const handleChangeRPFlow = (flow) => {
    deleteAssessments();
    const selectedProcessCloned = cloneDeep(selectedProcess)
    //remove old RP
    const oldRP = selectedProcessCloned.flows.find(e => e.referenceFlow === true)
    oldRP.customized = true
    oldRP.removed = true
    oldRP.referenceFlow = false

    //add new RP
    selectedProcessCloned.flows.unshift(flow)
    //reference product flow id
    selectedProcessCloned.referenceFlowId = flow.id
    selectedProcessCloned.referenceProductId = flow.flowBaseId
    selectedProcessCloned.referenceProductName = flow.name
    // console.log(selectedProcessCloned)
    setSelectedProcess(selectedProcessCloned)
  }

  const handleAddElementaryFlow = (elementaryFlow) => {
    deleteAssessments();
    const selectedProcessCloned = cloneDeep(selectedProcess)
    selectedProcessCloned.elementaryFlows.unshift(elementaryFlow)
    selectedProcessCloned.customized = true
    setSelectedProcess(selectedProcessCloned)
  }

  const handleOnClose = () => {
    //send the selectedProcess local state to ProductionLine redux state
    handleSelectedToggle("Input")
    modifyProcess(selectedProcess)
    onClose()
  }

  const handleOnCancelClose = () => {
    //send the selectedProcess local state to ProductionLine redux state
    handleSelectedToggle("Input")
    onClose()
  }

  const handleOnProcessChange = (value) => {
    setSelectedProcess(value)
  }


  return (
    <Dialog
      className="EditProcessModalComponent"
      fullWidth
      maxWidth={"xl"}
      onClose={handleOnCancelClose}
      open={open}
    >
      <DialogTitle>
        <div style={{display: "flex"}}>
          <Tooltip title={selectedProcess && selectedProcess.processBaseName}>
            <span>
              <Typography
                variant="h5">Characterize: {selectedProcess && truncate(selectedProcess.processBaseName)}
              </Typography>
            </span>
          </Tooltip>
          <ButtonGroup disableElevation variant="contained" color="primary" style={{marginLeft: "auto"}}>
            <Button color={selectedToggle === "Input" ? "primary" : "secondary"}
                    onClick={() => handleSelectedToggle("Input")}>Input</Button>
            <Button color={selectedToggle === "Output" ? "primary" : "secondary"}
                    onClick={() => handleSelectedToggle("Output")}>Output</Button>
            {productionLine.assessments[0].environmentalImpacts !== undefined ?
              <Button color={selectedToggle === "Assessment" ? "primary" : "secondary"}
                      onClick={() => handleSelectedToggle("Assessment")}>Assessment</Button> : null}
          </ButtonGroup>
        </div>
      </DialogTitle>
      <DialogContent>
        {selectedProcess &&
        <EditProcessContainer
          handleAddFlow={handleAddFlow}
          handleAddElementaryFlow={handleAddElementaryFlow}
          handleAddMixFlow={handleAddMixFlow}
          selectedProcess={selectedProcess}
          onProcessChange={handleOnProcessChange}
          selectedToggle={selectedToggle}
          handleOnChangeQuantity={handleOnChangeQuantity}
          handleOnBlurQuantity={handleOnBlurQuantity}
          handleRemoveFlow={handleRemoveFlow}
          handleRestoreFlow={handleRestoreFlow}
          handleRestoreFlowQty={handleRestoreFlowQty}
          handleChangeRPFlow={handleChangeRPFlow}
          handleOnChangeMixQuantity={handleOnChangeMixQuantity}
          handleOnBlurMixQuantity={handleOnBlurMixQuantity}
          handleRestoreMixQty={handleRestoreMixQty}
        />}
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={handleOnCancelClose} color="secondary">Cancel</Button>
        <Button variant={"text"} onClick={handleOnClose} color="primary">Done</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProcessModalComponent;
