import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { RainAnnuario } from './rainAnnuario';
// import { FlowAnnuario } from './flowAnnuario';
// import { IstSosList } from './istSosList';
// import { IstSos2056List } from './istSos2056List';
// import { SelectedView } from './selectedView';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import rootReducer from "./reducers/RootReducer";



//Developer: uncomment logger and import to debug

let createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(createStore);
// let createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk, logger))(createStore);

export default createStoreWithMiddleware(
  rootReducer,
);
// export const ConfigureStore = () => {
//   const store = createStore(
//     combineReducers({
//       rainAnnuario: RainAnnuario,
//       flowAnnuario: FlowAnnuario,
//       istSosList: IstSosList,
//       istSos2056List: IstSos2056List,
//       selectedView: SelectedView
//     }),
//     composeWithDevTools(
//       applyMiddleware(
//         thunk,
//         // logger
//       ),)
//   );
//   return store;
// }

//export default ConfigureStore;

