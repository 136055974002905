import _ from "lodash";
import React from "react";
import {
  Box, Button,
  Collapse, Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField, Tooltip
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RestoreIcon from "@material-ui/icons/Restore";
import ClearIcon from "@material-ui/icons/Clear";
import {FlowTypeEnum} from "../../../utils/enums/flowTypeEnum";
import AddElementaryFlowComponent from "./addFlow/AddElementaryFlowComponent";
import AddMixFlowComponent from "./addFlow/AddMixFlowComponent";

const MixTableRowComponent = ({
                                row,
                                handleOnChangeQuantity,
                                handleOnBlurQuantity,
                                handleOnChangeMixQuantity,
                                handleOnBlurMixQuantity,
                                handleRestoreFlowQty,
                                handleRestoreMixQty,
                                handleRemoveFlow,
                                handleRestoreFlow,
                                handleOpenAddFlowSection,
                                flowTypeEnum,
                                selectedToggle,
                                handleAddMixFlow,
                                addFlowSectionOpen,
                              }) => {
  // const {row} = props
  const rowClone = _.cloneDeep(row);
  const [open, setOpen] = React.useState(false);
  // const classes = useRowStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddMixFlowOpen = () => {
    if (addFlowSectionOpen !== flowTypeEnum) {
      handleOpenAddFlowSection(flowTypeEnum)
      // console.log(addFlowSectionOpen)
      // console.log(flowTypeEnum)
      // console.log("qui")
    } else {
      handleOpenAddFlowSection(undefined)
    }
  }

  // console.log(rowClone)
  return (
    <React.Fragment>
      <TableRow
        // className={classes.root}
        style={{background: rowClone.paretoIncluded ? '#a2cf6e' : null}}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rowClone.name}
        </TableCell>
        <TableCell align="right">
          <Box component="span" width={24} style={{display: "inline-block", verticalAlign: "bottom"}}>
            {(rowClone.quantity * rowClone.quantityFactor) !== rowClone.originalQuantity ?
              <RestoreIcon //style={{verticalAlign: "bottom"}}
                onClick={(event) => {
                  handleRestoreMixQty(rowClone.id, rowClone.flows);
                  event.stopPropagation();
                }}/> : null}
          </Box>
          <TextField id="standard-basic"
                     value={rowClone.quantity === "" ? rowClone.quantity : rowClone.quantity * rowClone.quantityFactor}
                     type={"number"}
                     inputProps={{lang: "en"}}
                     onBlur={
                       (e) => {
                         handleOnBlurMixQuantity(rowClone.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, rowClone.id, rowClone.flows)
                       }
                     }
                     onChange={
                       (e) => {
                         handleOnChangeMixQuantity(rowClone.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, rowClone.id)
                       }
                     }
          />{rowClone.unitOfMeasureName}
        </TableCell>
        <TableCell
          align="right">{rowClone.impactsContribute === 0 ? '-' : (rowClone.impactsContribute * 100).toFixed(2) + '%'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/*<Typography variant="h6" gutterBottom component="div">*/}
              {/*  Detail*/}
              {/*</Typography>*/}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Geography</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Unit quantity</TableCell>
                    <TableCell align="right">Impacts contribute</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? rowClone.flows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rowClone.flows
                  ).map((flow) => (
                    // {rowClone.flows.map((flow) => (
                    <TableRow key={flow.id}
                              style={{background: flow.paretoIncluded ? '#a2cf6e' : flow.referenceFlow ? '#ffff8b' : null}}
                    >
                      <TableCell component="th" scope="row">
                        {flow.removed ?
                          <span><RestoreIcon style={{verticalAlign: "bottom"}} onClick={(event) => {
                            handleRestoreFlow(flow.id, false);
                            event.stopPropagation();
                          }}/><del>{flow.name}</del></span>
                          :
                          <span><ClearIcon style={{verticalAlign: "bottom"}} onClick={(event) => {
                            // removeProcess(d.id);
                            handleRemoveFlow(flow.id, false);
                            event.stopPropagation();
                          }}/>{flow.name}</span>
                        }

                      </TableCell>
                      <TableCell>{flow.geographyFullName}({flow.geographyShortName})</TableCell>
                      <TableCell align="right">{flow.flowType}</TableCell>
                      <TableCell align="right">
                        <Box component="span" width={24} style={{display: "inline-block", verticalAlign: "bottom"}}>
                          {(flow.quantity * flow.quantityFactor) !== flow.originalQuantity ?
                            <RestoreIcon //style={{verticalAlign: "bottom"}}
                              onClick={(event) => {
                                handleRestoreFlowQty(flow.id, false);
                                event.stopPropagation();
                              }}/> : null}
                        </Box>
                        <TextField id="standard-basic"
                                   value={flow.quantity === "" ? flow.quantity : flow.quantity * flow.quantityFactor}
                                   type={"number"}
                                   inputProps={{lang: "en"}}
                                   onBlur={
                                     (e) => {
                                       handleOnBlurQuantity(flow.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, flow.id, flow.referenceFlow, false)
                                     }
                                   }
                                   onChange={
                                     (e) => {
                                       handleOnChangeQuantity(flow.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, flow.id, flow.referenceFlow, false)
                                     }
                                   }
                        />{flow.unitOfMeasureName}
                      </TableCell>
                      <TableCell
                        align="right">{flow.impactsContribute === 0 ? '-' : (flow.impactsContribute * 100).toFixed(2) + '%'}</TableCell>

                      {/*<TableCell align="right">*/}
                      {/*  {Math.round(historyRow.amount * row.price * 100) / 100}*/}
                      {/*</TableCell>*/}
                    </TableRow>
                  ))
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Tooltip title="Add a new flow">
                            <Button variant="contained" color={"primary"}
                                    onClick={() => handleAddMixFlowOpen()}
                            >
                              {addFlowSectionOpen === flowTypeEnum ? "Close" : "Add"}
                            </Button>
                          </Tooltip>
                        </Grid>
                    {/*    {flowTypeEnum === FlowTypeEnum.OUTPUT_FLOW ?*/}
                    {/*      <Grid item>*/}
                    {/*        <Tooltip title="Remove actual reference product flow of the process and add the selected one">*/}
                    {/*          <Button variant="contained" color={"primary"} onClick={() => handleChangeRPOpen()}>*/}
                    {/*            {(changeRPSectionOpen || createAndChangeRPSectionOpen) ? "Close" : "Change RP"}*/}
                    {/*          </Button>*/}
                    {/*        </Tooltip>*/}
                    {/*      </Grid> : null}*/}
                      </Grid>
                    </TableCell>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={5}
                      count={rowClone.flows.length === 0 ? 1 : rowClone.flows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      // SelectProps={{
                      //   inputProps: { 'aria-label': 'rows per page' },
                      //   native: true,
                      // }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
            <Box margin={1}>
              {addFlowSectionOpen === FlowTypeEnum.MIX_FLOW ?
                <AddMixFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                     selectedToggle={selectedToggle}
                                     handleAddMixFlow={handleAddMixFlow}/>
              : null }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default MixTableRowComponent;