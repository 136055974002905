import React, {useEffect, useState} from 'react';
import {AllInclusive, Build, Group, Search, Label} from "@material-ui/icons";
import {Button, Typography, Dialog, Grid, InputBase, List, ListItem, ListItemIcon, ListItemText, styled} from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function FilterComponent({items, isMainFilter, handleFilteredItem, currentFilter, title}) {
    const [open, setOpen] = useState(false);
    const [filteredItems, setFilteredItems] = useState(items)

    useEffect(() => {
        setFilteredItems(items)
    }, [items]);

    function handleClickOpen() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function handleItemClick(e, item) {
        handleFilteredItem(item, isMainFilter)
        handleClose()
    }

    function handleSearch(e) {
        let filtered = items.filter((str) => {
            return str.name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredItems(filtered)
    }

    return (
        <Grid container>
            <Grid item xs={2} style={{paddingTop: "1%"}}>
                <Typography>{title}</Typography>
                <Button
                    variant="outlined"
                    startIcon={<Search/>}
                    onClick={handleClickOpen}
                    style={{textTransform: 'none', color: "grey"}}>
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "150px",
                            whiteSpace: "nowrap", padding: "0px", textAlign: "left"
                        }}>
                            {currentFilter.name}
                        </span>
                </Button>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <div style={{minWidth: "400px", minHeight: "300px", maxHeight: "300px", padding: "10px"}}>
                        <div onClose={handleClose} style={{display: "flex"}}>
                            <div>
                                <Search/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                onChange={handleSearch}
                                autoFocus
                            />
                        </div>
                        <List>
                            {filteredItems.map((item, index) => {
                                return (
                                    <ListItem button key={index}>
                                        <ListItemIcon>
                                            {item.type === "ALL" ? <AllInclusive /> :
                                                item.type === "GROUP" ? <Group /> :
                                                item.type === "PROCESS" ? <Build /> : <Label />}
                                        </ListItemIcon>
                                        <ListItemText onClick={e => handleItemClick(e, item)}>
                                            {item.name}
                                        </ListItemText>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                </BootstrapDialog>
            </Grid>
        </Grid>
    );
}