import {calculateProductionLineUrl} from '../../shared/baseUrls';
import {modifyMethodology} from "./ProductionLineAction";
import {addAssessments} from "./ProductionLineAction";
import store from "../store";

import {LOADING_ASSESSMENT, FAILED_ASSESSMENT } from './ActionType';

export const calculateProductionLineAssessment = (methodology) => dispatch => {
  dispatch(loadingAssessment(true))
  dispatch(modifyMethodology(methodology))
  const state = store.getState();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(state.ProductionLineReducer.productionLine)
  };

  return fetch(calculateProductionLineUrl,requestOptions)
    .then(
      response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => response.payload)
    .then(productionLineWAssessments => {
      return dispatch(addAssessments(productionLineWAssessments))
    })
    .then(()=> { return dispatch(loadingAssessment(false))})
    .catch(error => dispatch(failedAssessment(error.message)));
};

export const loadingAssessment = (value) => ({
  type: LOADING_ASSESSMENT,
  payload: value,
});

export const failedAssessment = errmess => ({
  type: FAILED_ASSESSMENT,
  payload: errmess,
});

