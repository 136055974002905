import React from 'react';

import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import MixTableRowComponent from "./MixTableRowComponent";

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

function createData(mixInfo, mixFlows
                    // id, name, quantity, unitOfMeasureName, impactsContribute, paretoIncluded, price
) {
  // return {
  // name,
  // quantity,
  // unitOfMeasureName,
  // impactsContribute,
  // paretoIncluded,
  // price,

  // history: [
  //   {date: '2020-01-05', customerId: '11091700', amount: 3},
  //   {date: '2020-01-02', customerId: 'Anonymous', amount: 1},
  // ],
  // };
  mixInfo.flows = mixFlows
  return mixInfo;
}


// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

const MixTableComponent = ({
                             tableTitle,
                             mixes,
                             mixesFlows,
                             handleOnChangeQuantity,
                             handleOnBlurQuantity,
                             handleOnChangeMixQuantity,
                             handleOnBlurMixQuantity,
                             handleRestoreFlowQty,
                             handleRestoreMixQty,
                             handleRemoveFlow,
                             handleRestoreFlow,
                             flowTypeEnum,
                             handleOpenAddFlowSection,
                             selectedToggle,
                             addFlowSectionOpen,
                             handleAddMixFlow
                           }) => {
  const rows = [createData( mixes[0], mixesFlows.filter(e => e.flowDirection === "INPUT" && e.mixName!==null && e.mixName==="Electricity mix") )]
  // [
  // createData(parentData.id, parentData.name, parentData.quantity, parentData.unitOfMeasureName, parentData.impactsContribute, parentData.paretoIncluded)
  // createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  // createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  // createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  // createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  // createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  // ];
  // console.log(rows)
  return (
    <Grid container item xs={12} spacing={1}>
      <Typography variant="h6">{tableTitle}</Typography>
      <TableContainer>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>Name</TableCell>
              <TableCell align="right">Unit quantity</TableCell>
              <TableCell align="right">Impacts contribute</TableCell>
              {/*<TableCell align="right">Carbs&nbsp;(g)</TableCell>*/}
              {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <MixTableRowComponent key={row.id} row={row} handleOnChangeQuantity={handleOnChangeQuantity}
                                    handleOnBlurQuantity={handleOnBlurQuantity}
                                    handleOnChangeMixQuantity={handleOnChangeMixQuantity}
                                    handleOnBlurMixQuantity={handleOnBlurMixQuantity}
                                    handleRestoreFlowQty={handleRestoreFlowQty}
                                    handleRestoreMixQty={handleRestoreMixQty}
                                    handleRemoveFlow={handleRemoveFlow}
                                    handleRestoreFlow={handleRestoreFlow}
                                    flowTypeEnum={flowTypeEnum}
                                    handleOpenAddFlowSection={handleOpenAddFlowSection}
                                    selectedToggle={selectedToggle}
                                    addFlowSectionOpen={addFlowSectionOpen}
                                    handleAddMixFlow={handleAddMixFlow}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default MixTableComponent;