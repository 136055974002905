import {
  SHOW_ALERT,
  HIDE_ALERT,
} from "../actions/ActionType";

const DEFAULT_STATE = {
  display: false,
  severity: "success",
  message: "",
  autoHideDuration: 3000
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {...state, display: true,
        severity: action.payload.severity || "success",
        message: action.payload.message || "",
        autoHideDuration:action.payload.autoHideDuration === undefined ? 3000 : action.payload.autoHideDuration};
    case HIDE_ALERT:
      return {...state, display: false, severity: "success", errMess: null, message: "", autoHideDuration: 3000};
    default:
      return state;
  }
};
