import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: '#3e8e41',
      main: '#689f38'
      // main: '#618833',
      // main: '#8bc34a',

// main: '#71CC7B',
      // contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    secondary: {
      // main: '#A6DBAA',
      // main: 'rgba(255, 255, 255, 0.7)',
      main: 'rgba(0, 0, 0, 0.26)',
      // main: 'rgba(0, 0, 0, 0.12)',
      // contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    third: {
      main: '#e0e1e2',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;