import React from "react";
import {connect} from "react-redux";
import EditProcessComponent from "../../../components/dialogs/editProcess/EditProcessComponent";
// import {fetchProcessTemplate} from "../redux/actions/ProcessTemplateAction";
// import {fetchProcessAndAddToProductionLine} from "../redux/actions/ProductionLineAction";

const EditProcessContainer = props => {
  return <EditProcessComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    // processTemplates: state.ProcessTemplateReducer.processTemplates,
    // processTemplates_isLoading: state.ProcessTemplateReducer.isLoading,
    // processTemplates_errMess: state.ProcessTemplateReducer.errMess,
    // language: state.LanguageReducer.language,
    // width: state.ScreenReducer.width,
    // height: state.ScreenReducer.height,
    // view: state.ViewReducer.view,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  // fetchProcessTemplate: () => {
  //   dispatch(fetchProcessTemplate());
  // },
  // fetchProcessAndAddToProductionLine: (processId) => {
  //   dispatch(fetchProcessAndAddToProductionLine(processId));
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProcessContainer);
