import {Grid, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  fetchFlowBase_page,
  fetchFlows_page,
  fetchGeographies_page,
  fetchProcessTemplates_page
} from "../../../../utils/api";
import {cloneDeep} from "lodash";

import CustomAutocomplete from "../../../shared/CustomAutocomplete";

const args = {
  page: 0,
  size: 10,
  dir: "ASC",
  sort: "id",
}

const AddInputFlowComponent = ({handleOpenAddFlowSection, selectedToggle, handleAddFlow}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "ASC",
      property: "id"
    },
    loaded: false
  });

  const [selectedFlowBase, setSelectedFlowBase] = useState();

  const handleChangeFlowBases = (selectedFlowBase) => {
    setSelectedFlowBase(selectedFlowBase);
  }

  const handleFetchFlow = () => {

    const args1=Object.assign(
        cloneDeep(args),
      {size: 1},
        {flowBaseId: selectedFlowBase.id},
      {flowDirection: selectedToggle.toUpperCase() },
    )

    fetchFlows_page(args1).then(r => {
      const res = r.payload.content[0]
      res.quantity=0
      res.originalQuantity = 0
      res.added=true
      res.customized=true
      res.paretoIncluded=false
      res.referenceFlow=false
      res.impactsContribute=0
      r.payload.content[0]=res
      setPage(r.payload);
      handleAddFlow(res);
      handleOpenAddFlowSection(undefined)
    }
    )
  }


  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <CustomAutocomplete label = {"Name"}
                            likeChangeKey = {"name"}
                            showedProperty = {"name"}
                            handleSetValue={handleChangeFlowBases}
                            fetchCall={fetchFlowBase_page}
                            triggerObject={null}
                            disabled={false}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {name: null},
                              {flowDirection: selectedToggle.toUpperCase()}
                            )
                            }/>
      </Grid>
      <Grid container item
            xs={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <IconButton aria-label="delete"
                    color="primary"
                    disabled={selectedFlowBase? false : true}
                    onClick={handleFetchFlow}>
          <AddBoxIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AddInputFlowComponent;