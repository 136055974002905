import React from "react";
import ReactEcharts from "echarts-for-react";

import {Grid} from "@material-ui/core";

export default function ChartComponent({
                                           data,
                                           productionLine,
                                           onPieClick,
                                           onTitleClick,
                                           title,
                                           subtitle,
                                           isDetail,
                                           isGroup,
                                           showIndicator,
                                           currentMainFilter,
                                           currentIndicatorFilter
                                       }) {

    let colorPalette = ['#3ba272', '#91cc75', '#fac858', '#fc8452', '#da1e28', '#8a3800', '#750e13', '#ee6666', '#ffb3b8', '#fff1f1', '#e8daff', '#9a60b4', '#ea7ccc', '#73c0de', '#5470c6', '#012749']

    /*
    impactCategoryId: "349edd8a-9520-4e7c-a2ea-9ff9011148e3"
    impactCategoryName: "climate change"
    impactIndicatorId: "de876e60-3e62-46d9-b148-9ff9011148e3"
    impactIndicatorName: "total"
    methodologyId: "679a3f0b-79df-4527-af9e-9ff901114715"
    methodologyName: "IMPACT 2002+ (Endpoint)"
    unitOfMeasureName: "points"
    unitValue: 0
    value: 0.000090586455
    */

    /*processTemplateId(pin):"a25277164a5837f2bdbc84e2a9f354ed4a21befbb95d24fb0ee0211b7f7a2a11"
    processBaseId(pin):"3afc2a92-221c-4126-a6b7-58058d196623"
    referenceProductId(pin):"68d6dfcf-2089-4586-9bbf-ad75591105cf"
    processBaseName(pin):"injection moulding"
    referenceProductName(pin):"injection moulding"
    referenceFlowId(pin):"960ca2fc-46c5-496a-a2fb-c7a3038f68aa"
    locationAreaId(pin):"0723d252-7e2a-11de-9820-0019e336be3a"
    locationAreaName(pin):"RER"
    paretoApplied(pin):true
    paretoMethodologyId(pin):"679a3f0b-79df-4527-af9e-9ff901114715"
    paretoMethodologyName(pin):"IMPACT 2002+ (Endpoint)"
    paretoThreshold(pin):0.8
    customized(pin):false
    group(pin):"prova"
    name(pin):"injection moulding"*/

    /*console.log(data)
    console.log(productionLine)*/

    function getAcronym(str) {
        str = str.toUpperCase()
        let matches = str.match(/\b(\w)/g);
        return matches.join('');
    }

    function sumImpactValues(impacts) {
        if(currentIndicatorFilter.id === '') { // default filter
            const filtered = impacts.filter(({impactIndicatorName}) => !impactIndicatorName.includes('total'))
            const values = filtered.map(item => item.value);
            return values.reduce((sum, value) => sum + value)
        } else {
            const filtered = impacts.find((i) => i.impactIndicatorId === currentIndicatorFilter.id)
            return filtered.value
        }

    }

    function sumGroupsValues(groups) {
        let holder = {}
        groups.forEach(function (g) {
            if(g != null) {
                if (holder.hasOwnProperty(g.name)) {
                    holder[g.name] = holder[g.name] + g.value;
                } else {
                    holder[g.name] = g.value;
                }
            }
        })

        let result = [];
        for (let group in holder) {
            result.push({name: group, value: holder[group]})
        }
        return result.map(r => {
            return ({
                name: r.name,
                value: r.value.toExponential(2)
            })
        })
    }

    function computeData() {
        if (!data.length)
            return null
        if (showIndicator) {
            return data.map((d) => {
                return ({
                    value: d.value.toExponential(2),
                    name: isDetail ? `(${getAcronym(d.impactCategoryName)}), ${d.impactIndicatorName}` : `${d.impactCategoryName}, (${getAcronym(d.impactCategoryName)})`
                })
            })
        } else {
            let processes
            switch (currentMainFilter.type) {
                case "ALL":
                    processes = productionLine.processes
                    break;
                case "GROUP":
                    processes = productionLine.processes.filter(p => p.group === currentMainFilter.name)
                    break;
                case "PROCESS":
                    processes = productionLine.processes.filter(p => p.id === currentMainFilter.id)
            }
            // let result = data.map(a => a.name);
            if (isGroup) {
                const groups = processes.map((p) => {
                    if(p.assessments[0].environmentalImpacts === undefined)
                        return null
                    return ({
                            value: sumImpactValues(p.assessments[0].environmentalImpacts), // extract the filtered indicators
                            name: p.group ? p.group : p.name ? p.name : p.processBaseName
                        }
                    )
                })
                return sumGroupsValues(groups)
            } else {
                return processes.map((p) => {
                    if(p.assessments[0].environmentalImpacts === undefined)
                        return null
                    return ({
                            value: sumImpactValues(p.assessments[0].environmentalImpacts).toExponential(2),
                            name: p.name ? p.name : p.processBaseName
                        }
                    )
                })
            }
        }
    }

    const option = {
        title: {
            text: title,
            subtext: subtitle,
            left: 'center',
            triggerEvent: true
        },
        tooltip: {
            trigger: 'item',
            //formatter: '{a} <br/>{b}: {c} ({d}%)'
            formatter: "{b}: <b>{c} ({d}%)</b>"
        },
        legend: {
            show: false,
            orient: 'vertical',
            right: '40%',
            top: '5%',
            //formatter: `${impactCategoryName} {name}`
        },
        color: colorPalette,
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                showEmptyCircle: false,
                center: ['50%', '50%'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: true
                },
                labelLine: {
                    show: true
                },
                emphasis: {
                    label: {
                        show: true,
                        fontWeight: 'bold'
                    }
                },
                data: computeData()
            }
        ]
    };

    function chartClick(e) {
        if (e.componentType === "title") {
            if (onTitleClick !== undefined)
                onTitleClick(e)
        } else {
            if (onPieClick !== undefined)
                onPieClick(e)
        }
    }

    const pieChartAction = {
        'click': chartClick.bind(this)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ReactEcharts
                    option={option}
                    onEvents={pieChartAction}
                />
            </Grid>
        </Grid>
    );
}