import {processUrl} from '../../shared/baseUrls';
import React from "react";

export const ADD_PRODUCTION_LINE = 'ADD_PRODUCTION_LINE';
export const ADD_ASSESSMENTS = 'ADD_ASSESSMENTS';
export const MODIFY_METHODOLOGY = 'MODIFY_METHODOLOGY';
export const MODIFY_PROCESS = 'MODIFY_PROCESS';
export const ADD_PROCESS = 'ADD_PROCESS';
export const REMOVE_PROCESS = 'REMOVE_PROCESS';
export const LOADING_PROCESS = 'LOADING_PROCESS';
export const FAILED_PROCESS = 'FAILED_PROCESS';
export const DELETE_ASSESSMENTS = 'DELETE_ASSESSMENTS';

export const fetchProcessAndAddToProductionLine = (processId) => dispatch => {
  dispatch(loadingProcess(true));

  return fetch(processUrl + processId,
    // {mode:"no-cors"}
  )
    .then(
      response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => response.payload)
    .then(process => {
      return dispatch(addProcess(process))
    })
    .catch(error => dispatch(failedProcess(error.message)));
};

export const loadingProcess = () => ({
  type: LOADING_PROCESS,
});

export const failedProcess = errmess => ({
  type: FAILED_PROCESS,
  payload: errmess,
});

export const addProcess = process => ({
  type: ADD_PROCESS,
  payload: process,
});

export const modifyProcess = process => {
  return ({
  type: MODIFY_PROCESS,
  payload: process,
})};

export const modifyMethodology = methodology => ({
  type: MODIFY_METHODOLOGY,
  payload: methodology,
});

export const removeProcess = id => ({
  type: REMOVE_PROCESS,
  payload: id,
});

export const addAssessments = productionLineWAssessments => ({
  type: ADD_ASSESSMENTS,
  payload: productionLineWAssessments,
});

export const deleteAssessments = () => ({
  type: DELETE_ASSESSMENTS,
});

export const addProductionLine = productionLineWAssessments => ({
  type: ADD_PRODUCTION_LINE,
  payload: productionLineWAssessments,
});