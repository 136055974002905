
export const DELETE_ASSESSMENTS = 'DELETE_ASSESSMENTS';
export const ADD_PROCESS_TEMPLATES = 'ADD_PROCESS_TEMPLATES';
export const LOADING_PROCESS_TEMPLATES = 'LOADING_PROCESS_TEMPLATES';
export const FAILED_PROCESS_TEMPLATES = 'FAILED_PROCESS_TEMPLATES';

export const MODIFY_PROCESS = 'MODIFY_PROCESS';
export const ADD_PROCESS = 'ADD_PROCESS';
export const REMOVE_PROCESS = 'REMOVE_PROCESS';
export const LOADING_PROCESS = 'LOADING_PROCESS';
export const FAILED_PROCESS = 'FAILED_PROCESS';

export const ADD_METHODOLOGIES = 'ADD_METHODOLOGIES';
export const LOADING_METHODOLOGIES = 'LOADING_METHODOLOGIES';
export const FAILED_METHODOLOGIES = 'FAILED_METHODOLOGIES';

export const LOADING_ASSESSMENT = 'LOADING_ASSESSMENT';
export const FAILED_ASSESSMENT = 'FAILED_ASSESSMENT';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';


