import {Link} from "react-router-dom";
import { connect } from 'react-redux';


const SignIn = props => {
  return (
    <div>
      SignIn
      {/*<a href="/pagetwo">Navigate to Page Two, bad approach</a>*/}
      <Link to="/">Navigate to Home</Link>
    </div>
  );
};

const mapStateToProps = state => {
  return { song: state.selectedSong };
};

export default SignIn;