import React, {useEffect, useState} from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import {saveModelToIneditPlatform} from "../utils/api";

import {
  Button, ButtonGroup,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField, Tooltip,
  Typography
} from "@material-ui/core";
import AddProcessModalContainer from "../containers/dialogs/addProcess/AddProcessModalContainer";
import EditProcessModalContainer from "../containers/dialogs/editProcess/EditProcessModalContainer";
import _ from "lodash";
import ImportDialogComponent from "./dialogs/importDialog/ImportDialogComponent";
import {Alert} from "@material-ui/lab";

const ProcessListComponent = ({
                                manufacturerId,
                                lcaDataAlreadyExists,
                                productionLine,
                                removeProcess,
                                productionLine_isLoading,
                                modifyProcess,
                                showAlert,
                                deleteAssessments,
                                addProductionLine
                              }) => {
  const [data, setData] = useState(productionLine);
  const [isSaving, setIsSaving] = useState(false);
  const [statusSaving, setStatusSaving] = useState({isError: false, message: ""});
  const [openAddProcessModal, setOpenAddProcessModal] = React.useState(false)
  const [openEditProcessModal, setOpenEditProcessModal] = React.useState(false)
  const [openImporter, setOpenImporter] = useState(false)
  const [selectedProcessId, setSelectedProcessId] = React.useState(false)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnBlurRPQuantity = (oldQuantityFactor, newQuantityFactor, value, process) => {
    deleteAssessments();
    const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
    const qtyRP = newProcess.flows.find(e => e.referenceFlow === true).quantity
    newProcess.flows.find(e => e.referenceFlow === true).quantity = qtyRP === "" ? 0 : parseFloat(qtyRP)
    modifyProcess(newProcess)
  }

  const handleOnChangeRPQuantity = (oldQuantityFactor, newQuantityFactor, value, process) => {
    deleteAssessments();
    const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
    const rpFlow = newProcess.flows.find(e => e.referenceFlow === true)
    rpFlow.quantity = value.replace(/^-/, '')
    if (oldQuantityFactor !== newQuantityFactor && parseFloat(rpFlow.quantity)!==0 && rpFlow.quantity!=="" ) {

      showAlert({
        severity: "success",
        message: "It's not possible to invert the quantity sign for this type of flow",
        autoHideDuration: 3000
      })
    }
    modifyProcess(newProcess)
  }

  const handleOnChangeProcessBaseName = (value, process) => {
    const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
    newProcess.processBaseName = value
    modifyProcess(newProcess)
  }

  const handleOnBlurProcessBaseName = (value, process) => {
    if(!value){
      const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
      newProcess.processBaseName = "Default process name"
      modifyProcess(newProcess)

      showAlert({
        severity: "success",
        message: "Original process name field cannot be empty",
        autoHideDuration: 3000
      })
    }
  }

  const handleOnChangeProcessName = (value, process) => {
    const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
    newProcess.name = value
    modifyProcess(newProcess)
  }

  const handleOnChangeGroupName = (value, process) => {
    const newProcess = _.cloneDeep(productionLine.processes.find(e => e.id === process.id))
    newProcess.group = value
    modifyProcess(newProcess)
  }

  const handleExport =  () => {
    const prodLineClone = _.cloneDeep(productionLine);
    //delete assessments from prodLine
    delete prodLineClone.assessments[0].environmentalImpacts;
    delete prodLineClone.assessments[0].creationDate;
    delete prodLineClone.assessments[0].creationDateEpochSecond;
    //delete assessments from process
    prodLineClone.processes.forEach(p =>  {
      delete p.assessments[0].environmentalImpacts
      delete p.assessments[0].creationDate
      delete p.assessments[0].creationDateEpochSecond
    }
  )
    const jsonModel = JSON.stringify(prodLineClone);
    const a = document.createElement('a');
    a.textContent = 'model.json';
    a.download = "model.json";
    a.href = 'data:text/json;charset=utf-8,' + escape(jsonModel);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleSave =  () => {
    console.log(productionLine)

    if (manufacturerId && productionLine.processes.length !== 0){
      const prodLineClone = _.cloneDeep(productionLine);
      //delete assessments from prodLine
      delete prodLineClone.assessments[0].environmentalImpacts;
      delete prodLineClone.assessments[0].creationDate;
      delete prodLineClone.assessments[0].creationDateEpochSecond;
      //delete assessments from process
      prodLineClone.processes.forEach(p =>  {
            delete p.assessments[0].environmentalImpacts
            delete p.assessments[0].creationDate
            delete p.assessments[0].creationDateEpochSecond
          }
      )
      prodLineClone.manufacturerId = manufacturerId;
      setIsSaving(true)
      saveModelToIneditPlatform(manufacturerId, prodLineClone, lcaDataAlreadyExists)
          .then(r =>{
            setIsSaving(false)
            setStatusSaving({isError: false, message: "Processes saved successfully!"})
          })
          .catch(r => {
            setIsSaving(false)
            setStatusSaving({isError: true, message: "Error in saving processes!"})
          })
    }else{

      if (manufacturerId === undefined){
        setStatusSaving({isError: true, message: "Error in saving processes: no manufacturer specified!"})
      }else{
        setStatusSaving({isError: true, message: "Error in saving processes: please enter a process!"})
      }
    }
  }

const handleImportedFile = (fileConverted) => {
  addProductionLine(fileConverted);
}

  const handleImportDone = (errors) => {
    if (errors && errors.length > 0){
      // dispatch({
      //   type: "SHOW_ALERT",
      //   payload: {
      //     message: result.errors,
      //     severity: "error",
      //     autoHideDuration: null
      //   }
      // })
    }
      setOpenImporter(false)
  }
  useEffect(() => {
    setData(productionLine)
  }, [productionLine]);

  const handleOpenEditProcessModal = id => {
    setSelectedProcessId(id)
    setOpenEditProcessModal(true)
  };

  const processListTable = (tableTitle, tableHeaders) => {
    return (
      <Grid container>

        <Grid item xs={12}>
          <Typography variant="h6">
            {tableTitle}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <colgroup>
                <col style={{width: '15%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '25%'}}/>
                <col style={{width: '5%'}}/>
                <col style={{width: '25%'}}/>
                <col style={{width: '20%'}}/>
              </colgroup>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((tableHeader, index) => (
                    <TableCell key={index}>{tableHeader}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                    ? data.processes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : data.processes
                ).map((d, i) => {
                  const refFlow = d.flows.find(e => e.id === d.referenceFlowId)
                  return <TableRow
                    key={d.id}
                    onClick={() => handleOpenEditProcessModal(d.id)}
                  >
                    <TableCell>
                      <ClearIcon style={{verticalAlign: "bottom"}} onClick={(event) => {
                        removeProcess(d.id);
                        deleteAssessments();
                        event.stopPropagation();
                      }}/>
                      <TextField id="standard-basic"
                                 placeholder={"New process " + ((i + 1) + ((page) * rowsPerPage))}
                                 value={d.name||'' }
                                 onClick={(event) => {
                                   event.stopPropagation();
                                 }}
                                 onChange={
                                   (event) => {
                                     handleOnChangeProcessName(event.target.value, d);
                                   }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField id="standard-basic"
                                 placeholder={"New Group "}
                                 value={d.group||''}
                                 onClick={(event) => {
                                   event.stopPropagation();
                                 }}
                                 onChange={
                                   (event) => {
                                     handleOnChangeGroupName(event.target.value, d);
                                   }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField id="standard-basic"
                                 fullWidth
                                 value={d.processBaseName}
                                 onClick={(event) => {
                                   event.stopPropagation();
                                 }}
                                 onChange={
                                   (event) => {
                                     handleOnChangeProcessBaseName(event.target.value, d);
                                   }}
                                 onBlur={
                                   (event) => {
                                     handleOnBlurProcessBaseName(event.target.value, d);
                                   }}
/*                                 onBlur={
                                   (event) => {
                                     handleOnBlurRPQuantity(refFlow.quantityFactor, event.target.value >= 0 ? 1 : -1, event.target.value, d);
                                   }}*/
                      />
                    </TableCell>
                    <TableCell>{d.locationAreaName}</TableCell>
                    <TableCell>{d.referenceProductName}</TableCell>
                    <TableCell>
                      <TextField id="standard-basic"
                                 type={"number"}
                                 inputProps={{lang: "en"}}
                                 value={refFlow.quantity === "" ? refFlow.quantity : refFlow.quantity * refFlow.quantityFactor}
                                 onClick={(event) => {
                                   event.stopPropagation();
                                 }}
                                 onChange={
                                   (event) => {
                                     handleOnChangeRPQuantity(refFlow.quantityFactor, event.target.value >= 0 ? 1 : -1, event.target.value, d);
                                   }}
                                 onBlur={
                                   (event) => {
                                     handleOnBlurRPQuantity(refFlow.quantityFactor, event.target.value >= 0 ? 1 : -1, event.target.value, d);
                                   }}
                      />
                      {refFlow.unitOfMeasureName}</TableCell>
                  </TableRow>
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button variant="contained" color={"primary"} onClick={() => setOpenAddProcessModal(true)}>
                      Add process
                    </Button>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={5}
                    count={data.processes.length === 0 ? 1 : data.processes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
  return (
    <React.Fragment>
      <ImportDialogComponent
        onImported={handleImportDone}
        handleImportedFile={handleImportedFile}
        open={openImporter}
        onClose={() => setOpenImporter(false)}/>

      <AddProcessModalContainer
        open={openAddProcessModal}
        onClose={() => setOpenAddProcessModal(false)}
        onOpen={() => setOpenAddProcessModal(true)}
      />
      <EditProcessModalContainer
        open={openEditProcessModal}
        onClose={() => setOpenEditProcessModal(false)}
        onOpen={() => setOpenEditProcessModal(true)}
        selectedProcessId={selectedProcessId}
      />

      <Grid item xs={12}>
        {productionLine_isLoading ?
          <CircularProgress size={24}/>
          :
          null}
        {productionLine_isLoading ? null :
          processListTable("Process list:", ["Name", "Group", "Process", "Geography", "Reference Product", "Quantity"])}
      </Grid>

      <Grid item xs={12} m={4}>
        {!isSaving ?
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {/*<Tooltip title="Save to INEDIT Platform" arrow>
            <Button variant="text" color={"primary"} onClick={() => handleSave()}>
              Save
            </Button>
          </Tooltip>*/}
          <Button variant="text" color={"primary"} onClick={() => setOpenImporter(true)}>
            Import model
          </Button>
          <Button variant="text" color={"primary"} onClick={() => handleExport()}>
            Export model
          </Button>
        </ButtonGroup>:
            <Alert severity="info">
               Saving processes on INEDIT Platform <CircularProgress size={16}/>
            </Alert>
        }
        {statusSaving.message &&
            <Alert onClose={() => {setStatusSaving({isError: false, message: undefined})
            }} severity={statusSaving.isError?"error":"success"}>{statusSaving.message}</Alert>
        }
      </Grid>
    </React.Fragment>
  );
};

export default ProcessListComponent;