import React, {useEffect, useState} from 'react';
import ProcessListContainer from "../../containers/ProcessListContainer";
import {Box, CircularProgress, Grid} from "@material-ui/core";
import ImpactListContainer from "../../containers/ImpactListContainer";
import {parse} from "qs";
import {useLocation} from "react-router-dom";
import {fetchModelFromIneditPlatform, setLcaDataAlreadyExists} from "../../utils/api";
import {Alert} from "@material-ui/lab";

const ProductComponent = ({addProductionLine}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [statusFetching, setStatusFetching] = useState({isError: false, message: ""});

    const location = useLocation()
    //Use it for INEDIT to retrieve manufacturerId
    const manufacturerId = parse(location.search, {ignoreQueryPrefix: true}).manufacturerId
    // console.log(manufacturerId)


    const handleFetchModel = () => {
        /*
        const model_filename = window._env_.MODEL_FILENAME
        if (model_filename) {
          fetchModel().then(r => {
              addProductionLine(r)
            }
          )
        }
        */
        if (manufacturerId) {
            setIsFetching(true)
            fetchModelFromIneditPlatform(manufacturerId).then(
                r => {
                    //console.log(r.payload)
                    if (r.payload) {
                        setIsFetching(false)
                        addProductionLine(r.payload)
                        setLcaDataAlreadyExists(true)
                        setStatusFetching({
                           isError: false,
                           message: "Model loaded correctly for the selected manufacturer!"
                        })

                    } else {
                        setIsFetching(false)
                        setStatusFetching({isError: true, message: "No model found for the selected manufacturer!"})
                    }
                }
            ).catch(reason => {
                setIsFetching(false)
                setStatusFetching({isError: true, message: "No model found for the selected manufacturer!"})
            })
        }
    }

    useEffect(() => {
        handleFetchModel()
    }, [])


    return (
        <React.Fragment>
            <div className="Product">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ProcessListContainer manufacturerId={manufacturerId}/>
                        {isFetching ?
                            <Alert onClose={() => {
                                setIsFetching(false)
                            }} severity="info"> Fetching process from INEDTI Platform <CircularProgress
                                size={16}/></Alert>
                            : null}

                        {statusFetching.message &&
                            <Alert onClose={() => {
                                setStatusFetching({isError: false, message: undefined})
                            }} severity={statusFetching.isError ? "error" : "success"}>{statusFetching.message}</Alert>
                        }

                    </Grid>
                    <Grid item xs={12}> <Box m={3}/></Grid>
                    <Grid item xs={12}>
                        <ImpactListContainer/>
                    </Grid>
                </Grid>
            </div>

        </React.Fragment>
    );
};

export default ProductComponent;