import {Link} from "react-router-dom";
import { connect } from 'react-redux';

const ProductionLineComponent = () => {
  return (
    <div>
      PageTwo
      {/*<a href="/">Navigate to Page One, bad approach</a>*/}
      <Link to="/process-list">Navigate to Process</Link>
    </div>
  );
};

export default ProductionLineComponent;