import React, {useImperativeHandle} from 'react';
import '../styles/Header.css';

import {logoUrl} from "../shared/baseUrls";
import {AppBar, Avatar, Box, Button, Grid, makeStyles, Popover, Tab, Tabs} from "@material-ui/core";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {parse} from "qs";
import UserEntry from "./UserEntry";
import {startsWith} from "lodash";



const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const uDetailOpen = Boolean(anchorEl);

  const history = useHistory();
  const location = useLocation()
  const useStyles = makeStyles(theme => ({
    appBar: {
      backgroundColor: theme.palette.appBar,
      maxHeight:60
    },
    content: {
      padding: theme.spacing(3),
      flexGrow: 1,
    },
  }));

  const classes = useStyles();

  const initialPath = () => {
    switch(location.pathname) {
      case startsWith("/"):
        return "Process-list"
        break;
      case startsWith("/home"):
        return "Home"
        break;
      case startsWith("/login"):
        return "Login"
        break;
      default:
        return "Process-list"
    }
  }

    // location.pathname === "/" ? "Process-list" : location.pathname === "/home" ? "Home" : "Login"

  const [value, setValue] = React.useState(initialPath);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const myId = parse(location.search, {ignoreQueryPrefix: true}).id
  //
  // console.log(myId)


  return (
    <div className="Header">
      <Popover
        style={{zIndex: 1400 + 2} /*to compensate the height of the AppBar*/}
        open={uDetailOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UserEntry
          // userDetails={state.user} dispatch={dispatch}
        />
      </Popover>

      <AppBar position="fixed" className={classes.appBar}>
        <Grid container style={{width:"100%", maxHeight:60}}>
          <Grid item xs={1} style={{maxHeight:"inherit"}}
                style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img src={logoUrl + 'logo_SUPSI_resized.svg'} alt="SUPSI logo" style={{height:"50%"}} />
          </Grid>
          <Grid item xs={10}>
            <Tabs
              // variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs"
            >
              {/*<Tab label="Home" value={"Home"} component={NavLink} to={"/home"}/>*/}
              <Tab label="Processes" value={"Process-list"} component={NavLink} to={"/process-list"}/>
            </Tabs>
          </Grid>

          <Grid item xs={1}
                style={{display: "flex", justifyContent: "right", alignItems: "center", paddingRight:"16px"}}>
            {/*<Avatar*/}
            {/*  // onClick={handleMenu}*/}
            {/*  onClick={()=>history.push("/sign-in")}*/}
            {/*  style={{cursor: "pointer"}}>*/}
            {/*  /!*{state.user.name.substr(0, 1).toUpperCase()}*!/L*/}
            {/*</Avatar>*/}
          </Grid>
        </Grid>

        {/*        <Toolbar>*/}
        {/*          <Grid container>*/}
        {/*            <Grid item xs={1}>*/}
        {/*              <img src={logoUrl + 'logo_SUPSI_resized.svg'} alt="SUPSI logo" />*/}
        {/*            </Grid>*/}
        {/*            <Grid item xs={1}>*/}
        {/*              <NavLink exact className="inactive" activeClassName="active" to="/">Home</NavLink>*/}

        {/*            </Grid>*/}
        {/*            <Grid item xs={1}>*/}
        {/*              <NavLink  className="inactive" activeClassName="active" to="/process">Process</NavLink>*/}

        {/*            </Grid>*/}
        {/*            <Grid item xs={1}>*/}
        {/*              Hi!*/}
        {/*              <NavLink className="inactive" activeClassName="active" to="/sign-in"> Sign in </NavLink>*/}
        {/*              or*/}
        {/*              <NavLink className="inactive" activeClassName="active" to="/register"> register</NavLink>*/}
        {/*            </Grid>*/}
        {/*          </Grid>*/}

        {/*/!*            <nav style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%", height:"100%"}}>*!/*/}
        {/*/!*            <div style={{justifyContent: "flex-start", marginRight:"2%"}}>*!/*/}
        {/*/!*              <img src={logoUrl + 'logo_SUPSI_resized.svg'} alt="SUPSI logo" />*!/*/}
        {/*/!*            </div>*!/*/}
        {/*/!*            <div style={{justifyContent: "flex-start", flexBasis: "50px"}} >*!/*/}
        {/*/!*              <NavLink exact className="inactive" activeClassName="active" to="/">Home</NavLink>*!/*/}
        {/*/!*            </div>*!/*/}
        {/*/!*            <div style={{justifyContent: "flex-start", flexBasis: "50px"}}>*!/*/}
        {/*/!*              <NavLink  className="inactive" activeClassName="active" to="/process">Process</NavLink>*!/*/}
        {/*/!*            </div>*!/*/}
        {/*/!*            <div style={{justifyContent: "flex-start", flexBasis: "50px", flexGrow:"1"}}>*!/*/}
        {/*/!*            </div>*!/*/}
        {/*/!*            <div style={{justifyContent: "flex-end", flexBasis: "150px"}}>*!/*/}
        {/*/!*              Hi!*/}
        {/*              <NavLink className="inactive" activeClassName="active" to="/sign-in"> Sign in </NavLink>*/}
        {/*              or*/}
        {/*              <NavLink className="inactive" activeClassName="active" to="/register"> register</NavLink>*!/*/}
        {/*            </div>*/}
        {/*/!*          </nav>*!/*/}
        {/*        </Toolbar>*/}
      </AppBar>

      {/*<span className="logoNav">*/}
      {/*  <Image*/}
      {/*    src={logoUrl + 'logo_SUPSI.gif'}*/}
      {/*    // src = {process.env.PUBLIC_URL + '/assets/logo/logo_SUPSI.gif'}*/}
      {/*    size="small"*/}
      {/*    alt="Logo supsi"*/}
      {/*    className="imageLogo"*/}
      {/*  />*/}
      {/*</span>*/}


      {/*      <nav style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%", height:"100%"}}>
        <div style={{justifyContent: "flex-start", marginRight:"2%"}}>
          <img src={logoUrl + 'logo_SUPSI_resized.svg'} alt="SUPSI logo" />
        </div>
        <div style={{justifyContent: "flex-start", flexBasis: "50px"}} >
          <NavLink exact className="inactive" activeClassName="active" to="/">Home</NavLink>
        </div>
        <div style={{justifyContent: "flex-start", flexBasis: "50px"}}>
          <NavLink  className="inactive" activeClassName="active" to="/process">Process</NavLink>
        </div>
        <div style={{justifyContent: "flex-start", flexBasis: "50px", flexGrow:"1"}}>
        </div>
        <div style={{justifyContent: "flex-end", flexBasis: "150px"}}>
          Hi!
          <NavLink className="inactive" activeClassName="active" to="/sign-in"> Sign in </NavLink>
          or
          <NavLink className="inactive" activeClassName="active" to="/register"> register</NavLink>
        </div>
      </nav>*/}
    </div>

  );
};

export default Header;
