import {
  ADD_METHODOLOGIES,
  LOADING_METHODOLOGIES,
  FAILED_METHODOLOGIES,
} from "../actions/MethodologiesAction";

const DEFAULT_STATE = {
  isLoading: true,
  errMess: null,
  methodologies: [],
};

export default (state = DEFAULT_STATE, action) => {
  // console.log(action.type)
  // console.log(action.payload)
  switch (action.type) {
    case ADD_METHODOLOGIES:
      return {...state, isLoading: false, errMess: null, methodologies: action.payload};
    case LOADING_METHODOLOGIES:
      return {...state, isLoading: true, errMess: null, methodologies: []};
    case FAILED_METHODOLOGIES:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};
