import {methodologiesUrl} from '../../shared/baseUrls';

export const ADD_METHODOLOGIES = 'ADD_METHODOLOGIES';
export const LOADING_METHODOLOGIES = 'LOADING_METHODOLOGIES';
export const FAILED_METHODOLOGIES = 'FAILED_METHODOLOGIES';


export const fetchMethodologies = () => dispatch => {
  dispatch(loadingMethodologies(true));

  return fetch(methodologiesUrl,
    // {mode:"no-cors"}
  )
    .then(
      response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => response.payload)
    .then(methodologies => {
      // console.log(processTemplates);
      return dispatch(addMethodologies(methodologies.methodologies))
    })
    .catch(error => dispatch(failedMethodologies(error.message)));
};

export const loadingMethodologies = () => ({
  type: LOADING_METHODOLOGIES,
});

export const failedMethodologies = errmess => ({
  type: FAILED_METHODOLOGIES,
  payload: errmess,
});

export const addMethodologies = methodologies => {
  return({
    type: ADD_METHODOLOGIES,
    payload: methodologies,
  });
};
