// export const baseUrl = 'http://sp-dev_sp-platform_1:8080';
// export const baseUrl = 'http://isteps-sps-01.supsi.ch';
// export const baseUrl = 'http://localhost:8080';
// export const contextPath = '/sp-api';
// console.log(window._env_.API_URL);
// console.log(window._env_.MODEL);

export const contextPath = '/sp-api';
export const generalApi = '/general/v1';
export const ineditApi = '/inedit/v1';
// export const composedBaseUrl = baseUrl + contextPath + '/api';
export const logoUrl = process.env.PUBLIC_URL + '/assets/logo/';
// export const processTemplateUrl = composedBaseUrl + '/sust/general/v1' + '/processTemplates';
export const processTemplateUrl = contextPath + '/api' + '/sust' + ineditApi + '/processTemplates';
export const processUrl = contextPath + '/api' + '/app'+ ineditApi + '/processes'+ '/create?processTemplateId=';
export const manufacturerProcessesUrl = contextPath + '/api' + '/app'+ ineditApi + '/processes'+ '/manufacturer/';
export const lcaDataUrl = '/lcadata';
export const methodologiesUrl = contextPath + '/api' + '/sust' + ineditApi + '/methodologies';
export const calculateProductionLineUrl = contextPath + '/api' + '/app' + ineditApi + '/assessment/productionLine/calculate';

/*** INEDIT APIs ***/
// export const corsUrl = 'https://cors-anywhere.herokuapp.com/'
export const ineditPlatformbaseUrl = 'https://uat.designtogether.eu';
export const ineditPlatformApi = '/apis';
export const ineditPlatformLCADataApi = '/lcadata';
export const ineditPlatformLCAImpactDataApi = '/lcaimpact';
export const ineditLoginApi = ineditPlatformbaseUrl + ineditPlatformApi + '/login';