import {HIDE_ALERT, SHOW_ALERT} from './ActionType';

export const showAlert = (values) => {
  return {
    type: SHOW_ALERT,
    payload: values,
  }
};

export const hideAlert = () => ({
  type: HIDE_ALERT,
});
