// import {Link} from "react-router-dom";
import '../styles/Main.css';
import {Route, Switch} from "react-router-dom";
import ProductionLineContainer from '../containers/ProductionLineContainer';
import ProductContainer from '../containers/pages/ProductContainer';
import Register from "./Register";
import SignIn from "./SignIn";
import {Grid, IconButton, makeStyles, Snackbar} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from "@material-ui/lab";

const MainComponent = ({alert, hideAlert}) => {

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(3), flexGrow: 1,
    },
  }));

  const classes = useStyles();

  return (<div className={classes.content}>
      <Switch>
        <Route path="/" exact component={ProductContainer}/>
        <Route path="/process-list" component={ProductContainer}/>
        <Route path="/home" component={ProductionLineContainer}/>
        <Route path="/sign-in" component={SignIn}/>
        <Route path="/register" component={Register}/>
      </Switch>

      <Snackbar
        open={alert.display}
        autoHideDuration={alert.autoHideDuration}
        onClose={(e, reason) => {
          hideAlert();
        }}

      >
        <Alert severity={alert.severity}>
          <Grid container>
            <Grid item xs={11}>
              {(Array.isArray(alert.message) ? <ul>
                {alert.message.map(txt => <li key={txt}>{txt}</li>)}
              </ul> : alert.message)}
            </Grid>
            <Grid item xs={1}>

              <IconButton size="small"
                          onClick={() => hideAlert()}
              >
                <CloseIcon fontSize="small"/>
              </IconButton>

            </Grid>
          </Grid>

        </Alert>
      </Snackbar>

    </div>);
};

export default MainComponent;
