import React, {useEffect} from 'react';
import FlowsTableComponent from "../../shared/FlowsTableComponent";
import {FlowTypeEnum} from "../../../utils/enums/flowTypeEnum"
import AddInputFlowComponent from "./addFlow/AddInputFlowComponent";
import {Card, CardContent, Grid} from "@material-ui/core";
import AddElementaryFlowComponent from "./addFlow/AddElementaryFlowComponent";
import AddOutputFlowComponent from "./addFlow/AddOutputFlowComponent";
import ImpactsTableComponent from "../../shared/ImpactsTableComponent";
import ChangeRPComponent from "./addFlow/ChangeRPComponent";
import CreateAndChangeRPComponent from "./addFlow/CreateAndChangeRPComponent";
import MixTableComponent from "./MixTableComponent";

const EditProcessComponent = ({
                                selectedProcess,
                                selectedToggle,
                                handleOnChangeQuantity,
                                handleOnBlurQuantity,
                                handleAddFlow,
                                handleAddElementaryFlow,
                                handleAddMixFlow,
                                handleRemoveFlow,
                                handleRestoreFlow,
                                handleRestoreFlowQty,
                                handleChangeRPFlow,
                                handleOnChangeMixQuantity,
                                handleOnBlurMixQuantity,
                                handleRestoreMixQty
                              }) => {

  const [addFlowSectionOpen, setAddFlowSectionOpen] = React.useState(false);
  const [changeRPSectionOpen, setChangeRPSectionOpen] = React.useState(false);
  const [createAndChangeRPSectionOpen, setCreateAndChangeRPSectionOpen] = React.useState(false);

  const handleOpenAddFlowSection = (flowTypeEnum) => {
    setAddFlowSectionOpen(flowTypeEnum);
    setChangeRPSectionOpen(false);
    setCreateAndChangeRPSectionOpen(false);
  };

  const handleOpenChangeRPSection = (value) => {
    setChangeRPSectionOpen(value);
    setAddFlowSectionOpen(undefined);
    setCreateAndChangeRPSectionOpen(false);
  };

  const handleOpenCreateAndChangeRPSection = (value) => {
    setCreateAndChangeRPSectionOpen(value);
    setAddFlowSectionOpen(undefined);
    setChangeRPSectionOpen(false);
  };

  const closeAllBottomAdd = () => {
    setCreateAndChangeRPSectionOpen(false);
    setAddFlowSectionOpen(undefined);
    setChangeRPSectionOpen(false);
  }

  useEffect(() => {
    closeAllBottomAdd()
  }, [selectedToggle]);

  return (<Grid container spacing={4}>

    {/*Input mix*/}
    {selectedToggle === "Input" && selectedProcess.flows.filter(e => e.flowDirection === "INPUT").length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <MixTableComponent
                tableTitle={"Mixes:"}
                mixes={selectedProcess.mixes}
                mixesFlows={selectedProcess.flows.filter(e => e.mixId !== null)}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOnChangeMixQuantity={handleOnChangeMixQuantity}
                handleOnBlurMixQuantity={handleOnBlurMixQuantity}
                handleRestoreFlowQty={handleRestoreFlowQty}
                handleRestoreMixQty={handleRestoreMixQty}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                flowTypeEnum={FlowTypeEnum.MIX_FLOW}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                selectedToggle={selectedToggle}
                addFlowSectionOpen={addFlowSectionOpen}
                handleAddMixFlow={handleAddMixFlow}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {/*Input flows*/}
    {selectedToggle === "Input" && selectedProcess.flows.filter(e => e.flowDirection === "INPUT").length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <FlowsTableComponent
                tableTitle="Input flows:"
                data={selectedProcess.flows.filter(e => e.flowDirection === "INPUT" && !("mixId" in e))}
                dataKeyNames={[]}
                tableHeaders={["Name", "Geography", "Type", "Unit quantity", "Impacts Contribute"]}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                isElementary={false}
                flowTypeEnum={FlowTypeEnum.INPUT_FLOW}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                handleRestoreFlowQty={handleRestoreFlowQty}
                addFlowSectionOpen={addFlowSectionOpen}
                changeRPSectionOpen={changeRPSectionOpen}
                handleOpenChangeRPSection={handleOpenChangeRPSection}
                createAndChangeRPSectionOpen={createAndChangeRPSectionOpen}
              />

              {addFlowSectionOpen === FlowTypeEnum.INPUT_FLOW ?
                <AddInputFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                       selectedToggle={selectedToggle}
                                       handleAddFlow={handleAddFlow}/> : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {/*Input elementary flows*/}
    {selectedToggle === "Input" && selectedProcess.elementaryFlows.filter(e => e.flowDirection === "INPUT").length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <FlowsTableComponent
                tableTitle="Input elementary flows:"
                data={selectedProcess.elementaryFlows.filter(e => e.flowDirection === "INPUT")}
                dataKeyNames={[]}
                tableHeaders={["Name", "Type", "Unit quantity", "Impacts Contribute"]}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                isElementary={true}
                flowTypeEnum={FlowTypeEnum.INPUT_ELEMENTARY}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                handleRestoreFlowQty={handleRestoreFlowQty}
                addFlowSectionOpen={addFlowSectionOpen}
                changeRPSectionOpen={changeRPSectionOpen}
                handleOpenChangeRPSection={handleOpenChangeRPSection}
                createAndChangeRPSectionOpen={createAndChangeRPSectionOpen}
              />

              {addFlowSectionOpen === FlowTypeEnum.INPUT_ELEMENTARY ?
                <AddElementaryFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                            selectedToggle={selectedToggle}
                                            handleAddElementaryFlow={handleAddElementaryFlow}/> : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {/*RP Flow*/}
    {selectedToggle === "Output" && selectedProcess.flows.filter(e => e.flowDirection === "OUTPUT" && e.referenceFlow).length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <FlowsTableComponent
                tableTitle="Reference Product:"
                data={selectedProcess.flows.filter(e => e.flowDirection === "OUTPUT" && e.referenceFlow)}
                dataKeyNames={[]}
                tableHeaders={["Name", "Type", "Unit quantity"]}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                isElementary={false}
                isReferenceProduct={true}
                flowTypeEnum={FlowTypeEnum.OUTPUT_FLOW}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                handleRestoreFlowQty={handleRestoreFlowQty}
                addFlowSectionOpen={addFlowSectionOpen}
                changeRPSectionOpen={changeRPSectionOpen}
                handleOpenChangeRPSection={handleOpenChangeRPSection}
                createAndChangeRPSectionOpen={createAndChangeRPSectionOpen}
              />

              {addFlowSectionOpen === FlowTypeEnum.OUTPUT_FLOW ?
                <AddOutputFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                        selectedToggle={selectedToggle}
                                        handleAddFlow={handleAddFlow}/> : null}
              {changeRPSectionOpen ?
                <ChangeRPComponent handleOpenChangeRPSection={handleOpenChangeRPSection}
                                   selectedToggle={selectedToggle}
                                   handleOpenCreateAndChangeRPSection={handleOpenCreateAndChangeRPSection}
                                   handleChangeRPFlow={handleChangeRPFlow}/> : null}
              {createAndChangeRPSectionOpen ?
                <CreateAndChangeRPComponent handleOpenChangeRPSection={handleOpenChangeRPSection}
                                            processBaseId={selectedProcess.processBaseId}
                                            handleChangeRPFlow={handleChangeRPFlow}
                /> : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {/*Output flows*/}
    {selectedToggle === "Output" && selectedProcess.flows.filter(e => e.flowDirection === "OUTPUT").length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <FlowsTableComponent
                tableTitle="Output flows:"
                data={selectedProcess.flows.filter(e => e.flowDirection === "OUTPUT" && !e.referenceFlow )}
                dataKeyNames={[]}
                tableHeaders={["Name", "Type", "Unit quantity"]}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                isElementary={false}
                flowTypeEnum={FlowTypeEnum.OUTPUT_FLOW}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                handleRestoreFlowQty={handleRestoreFlowQty}
                addFlowSectionOpen={addFlowSectionOpen}
                changeRPSectionOpen={changeRPSectionOpen}
                handleOpenChangeRPSection={handleOpenChangeRPSection}
                createAndChangeRPSectionOpen={createAndChangeRPSectionOpen}
              />

              {addFlowSectionOpen === FlowTypeEnum.OUTPUT_FLOW ?
                <AddOutputFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                        selectedToggle={selectedToggle}
                                        handleAddFlow={handleAddFlow}/> : null}
              {changeRPSectionOpen ?
                <ChangeRPComponent handleOpenChangeRPSection={handleOpenChangeRPSection}
                                   selectedToggle={selectedToggle}
                                   handleOpenCreateAndChangeRPSection={handleOpenCreateAndChangeRPSection}
                                   handleChangeRPFlow={handleChangeRPFlow}/> : null}
              {createAndChangeRPSectionOpen ?
                <CreateAndChangeRPComponent handleOpenChangeRPSection={handleOpenChangeRPSection}
                                            processBaseId={selectedProcess.processBaseId}
                                            handleChangeRPFlow={handleChangeRPFlow}
                /> : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {/*Output elementary flows*/}
    {selectedToggle === "Output" && selectedProcess.elementaryFlows.filter(e => e.flowDirection === "OUTPUT").length !== 0 &&
      <Grid container item xs={12} spacing={1}>
        <Card style={{width: "100%"}} variant="elevation">
          <CardContent>
            <Grid container item xs={12} spacing={1}>
              <FlowsTableComponent
                tableTitle="Output elementary flows:"
                data={selectedProcess.elementaryFlows.filter(e => e.flowDirection === "OUTPUT")}
                dataKeyNames={[]}
                tableHeaders={["Name", "Type", "Unit quantity", "Impacts Contribute"]}
                handleOnChangeQuantity={handleOnChangeQuantity}
                handleOnBlurQuantity={handleOnBlurQuantity}
                handleOpenAddFlowSection={handleOpenAddFlowSection}
                isElementary={true}
                flowTypeEnum={FlowTypeEnum.OUTPUT_ELEMENTARY}
                handleRemoveFlow={handleRemoveFlow}
                handleRestoreFlow={handleRestoreFlow}
                handleRestoreFlowQty={handleRestoreFlowQty}
                addFlowSectionOpen={addFlowSectionOpen}
                changeRPSectionOpen={changeRPSectionOpen}
                handleOpenChangeRPSection={handleOpenChangeRPSection}
                createAndChangeRPSectionOpen={createAndChangeRPSectionOpen}
              />

              {addFlowSectionOpen === FlowTypeEnum.OUTPUT_ELEMENTARY ?
                <AddElementaryFlowComponent handleOpenAddFlowSection={handleOpenAddFlowSection}
                                            selectedToggle={selectedToggle}
                                            handleAddElementaryFlow={handleAddElementaryFlow}/> : null}

            </Grid>
          </CardContent>
        </Card>
      </Grid>}

    {selectedToggle === "Assessment" && <Grid container item xs={12} spacing={1}>
      <Card style={{width: "100%"}} variant="elevation">
        <CardContent>
          <Grid container item xs={12} spacing={1}>
            <ImpactsTableComponent
              tableTitle="Assessment"
              data={selectedProcess.assessments[0].environmentalImpacts}
              tableHeaders={["Category", "Indicator", "Value"]}
              isProductPage={false}
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>}

    {/*
      <a href="/pagetwo">Navigate to Page Two, bad approach</a>
      <Link to="/production-line">Navigate to ProductionLineComponent</Link>*/}
  </Grid>);
};

export default EditProcessComponent;