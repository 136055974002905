import React, {useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {throttle} from "lodash";
// import {fetchFlowBase} from "../../utils/api";

// const pageNr = 0
// const size = 10
// const dir = "DESC"
// const sort = "id"

const CustomAutocomplete = ({label, likeChangeKey, showedProperty, handleSetValue, fetchCall, args, triggerObject, disabled}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "DESC",
      property: "id"
    },
    loaded: false
  });

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    handleSetValue(value)
  }, [value]);

  const fetch = React.useMemo(
    () =>
      throttle((args) => {
        fetchCall(args).then(r => {
            setPage(r.payload);
          }
        )
      }, 500),
    [],
  );

  React.useEffect(() => {

    let newOptions = [];

    if (value) {
      newOptions = [value];
    }

    if (page.content) {
      newOptions = [...newOptions, ...page.content];
    }

    setOptions(newOptions);
  }, [page])

  React.useEffect(() => {
    args = {...args}
    args[likeChangeKey] = inputValue

    let isNullPresent=false
    for (const [key, value] of Object.entries(args)) {
      // console.log(`${key}: ${value}`);
      if (value===null){
        isNullPresent=true
      }
    }

    // console.log("_____________")

    // if (inputValue === '') {
    //only if there are args null value return empty options
    if(isNullPresent){
      setOptions(value ? [value] : []);
      return undefined;
      // args = {...args}
      // args[likeChangeKey] = inputValue
      //
      // fetch(args);
    }

    // const args = {
    //   page: pageNr,
    //   size: size,
    //   sortDir: dir,
    //   sortField: sort,
    //   name: inputValue
    // }

    // args = Object.assign(args, {name: inputValue})



    fetch(args);
  }, [value, inputValue, fetch, triggerObject]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) =>{
      // {console.log(option)}
      return(
        typeof option === 'string' ? option : option[showedProperty])
        // typeof option === 'string' ? option : option.name
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth/>
      )}
    />)
}

export default CustomAutocomplete;