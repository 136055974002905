import React from "react";
import {connect} from "react-redux";
import ProductComponent from "../../components/pages/ProductComponent";
import {fetchProcessTemplate} from "../../redux/actions/ProcessTemplateAction";
import {addProductionLine, fetchProcessAndAddToProductionLine} from "../../redux/actions/ProductionLineAction";

const ProductContainer = props => {
  return <ProductComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    processTemplates: state.ProcessTemplateReducer.processTemplates,
    processTemplates_isLoading: state.ProcessTemplateReducer.isLoading,
    processTemplates_errMess: state.ProcessTemplateReducer.errMess,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProcessTemplate: () => {
    dispatch(fetchProcessTemplate());
  },
  fetchProcessAndAddToProductionLine: (processId) => {
    dispatch(fetchProcessAndAddToProductionLine(processId));
  },
  addProductionLine: (productionLineWAssessments) => {
    dispatch(addProductionLine(productionLineWAssessments))
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductContainer);
