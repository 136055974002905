import React from "react";
import {connect} from "react-redux";
import ImpactListComponent from "../components/ImpactListComponent";
import {fetchMethodologies} from "../redux/actions/MethodologiesAction";
import {calculateProductionLineAssessment} from "../redux/actions/AssessmentAction";
import AssessmentReducer from "../redux/reducers/AssessmentReducer";

const ImpactListContainer = props => {
  return <ImpactListComponent {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    productionLine: state.ProductionLineReducer.productionLine,
    productionLine_isLoading: state.ProductionLineReducer.isLoading,
    productionLine_errMess: state.ProductionLineReducer.errMess,
    methodologies: state.MethodologiesReducer.methodologies,
    assessments_isLoading: state.AssessmentReducer.isLoading,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMethodologies: () => {
    dispatch(fetchMethodologies());
  },
  calculateProductionLineAssessment: (methodology) => {
    dispatch(calculateProductionLineAssessment(methodology))
  },

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactListContainer);
