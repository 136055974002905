import {BrowserRouter, Route} from "react-router-dom";
import '../styles/App.css';
import Header from "./Header";
import Footer from "./Footer";
import {Grid, IconButton, makeStyles} from "@material-ui/core";
import MainContainer from "../containers/MainContainer";
import {useState} from "react";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';



const ribbonStyle = {
  width: "200px",
  padding: "8px",
  // position: "absolute",
  textAlign: "center",
  color: "#f0f0f0",
  top: "25px",
  left: "-50px",
  transform: "rotate(-45deg)",
  position: "fixed",
  fontSize: "20px",
  zIndex: 10000
}

const App = () => {

  const useStyles = makeStyles(theme => ({
    app: {
      display: 'flex',
      marginTop: 60
    },
  }));

  const [dev, setDev] = useState(window._env_.DEV === "true")
  const [loc, setLocal] = useState(window._env_.LOCAL === "true" && window._env_.DEV === "false")

  const handleRibbonHide = () =>{
    const rbnTxt = loc ? "Are you sure? This remains a local environment even if you remove the ribbon :)" : "Are you sure? This remains a dev environment even if you remove the ribbon :)"

    if (window.confirm(rbnTxt)) {
      setDev(false)
      setLocal(false)
    }
  }


  const classes = useStyles();
  return (
    <div className={classes.app}>

      {dev && !loc &&
      <div style={{backgroundColor: "#e43" ,...ribbonStyle}}>
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center">
          <Grid item>
            <IconButton size={"small"} component="span" onClick={handleRibbonHide}>
              <VisibilityOffIcon style={{transform: "rotate(45deg)"}} />
            </IconButton>
          </Grid>
          <Grid item>
            DEV
          </Grid>
        </Grid>
      </div>}

      {loc &&
      <div style={{backgroundColor: "#337eee" ,...ribbonStyle}}>
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center">
          <Grid item>
            <IconButton size={"small"} component="span" onClick={handleRibbonHide}>
              <VisibilityOffIcon style={{transform: "rotate(45deg)"}} />
            </IconButton>
          </Grid>
          <Grid item>
            LOCAL
          </Grid>
        </Grid>
      </div>}




      <BrowserRouter
        // basename={"/sp"}
      >
        <Header />
        {/*<Route path="/" exact component={ProductionLineComponent} />*/}
        {/*<Route path="/process" component={AddProcessComponent} />*/}
        {/*<Route path="/production-line" component={ProductionLineComponent} />*/}
        <MainContainer />
        {/*<Footer />*/}
      </BrowserRouter>
      {/*      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>*/}
      <Footer/>
    </div>
  );
}

export default App;