import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import FileInputComponent from "../../FileInputComponent";
import { useState} from "react";

export default function ImportDialogComponent({open, onClose, onImported, handleImportedFile}) {
  // const [state] = useContext(AppContext)
  // const jwt = state.user.jwt

  const [file, setFile] = useState();
  // const {projectId} = useParams();

  const [importLoading, setImportLoading] = useState(false);

  const handleImport = () => {
    setImportLoading(true)
    handleImportedFile(file)
    setImportLoading(false)
    onImported(undefined)
    // const payload = {
    //   data: file
    // }
    // console.log(file);
    // putMassiveImport(jwt.token, payload, projectId)
    //   .then(onImported)
    //   .catch(e => alert(e.message))
    //   .then(() => setImportLoading(false))
  }

  return (
    <Dialog maxWidth={"sm"} fullWidth open={open} onClose={onClose}
            // onExited={() => {}}
    >
      <DialogTitle>
        Import model
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12} style={{marginBottom: 2}}>
            <FileInputComponent onSelected={setFile} label={"File"}/>
          </Grid>
          <Grid item>
            <Button variant={"outlined"} onClick={handleImport}>
              {importLoading && <CircularProgress size={25}/>}
              Import
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}