import React, {useContext} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import {AppContext} from "../App";
import {useHistory} from "react-router-dom";


export default function UserEntry() {
  // const [state, dispatch] = useContext(AppContext);
  const history = useHistory();

  const handleLogout = () => {

    // // mando l'azione di logout
    // dispatch({
    //   type: "LOGOUT"
    // });
    history.push("/login")
  }


  return (
    <List style={{marginTop: "auto", cursor: "pointer"}}>

      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {/*{state.user.name.substr(0, 1).toUpperCase()}*/}
            D
          </Avatar>
        </ListItemAvatar>
        {/*<ListItemText primary={state.user.name + ", " + state.user.surname} secondary={state.user.email}/>*/}
        <ListItemText primary={"Davide" + ", " + "Zanin"} secondary={"davide.zanin@supsi.ch"}/>
      </ListItem>

      <Divider/>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon/>
        </ListItemIcon>
        <ListItemText primary="Logout"/>
      </ListItem>
    </List>


  )
}