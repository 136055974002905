import {Grid, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  fetchElementaryFlow_page,
  fetchElementaryFlowBase_page,
  fetchElementaryFlowCategory_page,
  fetchElementaryFlowSubCategory_page,
} from "../../../../utils/api";
import {cloneDeep} from "lodash";

import CustomAutocomplete from "../../../shared/CustomAutocomplete";

const args = {
  page: 0,
  size: 10,
  dir: "ASC",
  sort: "id",
}

const AddElementaryFlowComponent = ({handleOpenAddFlowSection, selectedToggle, handleAddElementaryFlow}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "ASC",
      property: "id"
    },
    loaded: false
  });

  const [selectedElementaryFlowBaseName, setSelectedElementaryFlowBaseName] = useState();
  const [selectedEFCategory, setSelectedEFCategory] = useState();
  const [selectedEFSubCategory, setSelectedEFSubCategory] = useState();

  const handleChangeElementaryFlowBases = (selectedElementaryFlowBaseName) => {
    setSelectedElementaryFlowBaseName(selectedElementaryFlowBaseName);
  }

  const handleChangeEFCategory = (selectedEFCategory) => {
    setSelectedEFCategory(selectedEFCategory);
  }

  const handleChangeLinkedProcessTemplate = (selectedEFSubCategory) => {
    setSelectedEFSubCategory(selectedEFSubCategory);
  }

  const handleFetchEFlow = () => {

    const args1=Object.assign(
        cloneDeep(args),
      {size: 1},
      {sort: 'elementaryFlowBase.name'},
      {elementaryFlowBaseName: selectedElementaryFlowBaseName},
      {elementaryFlowCategoryId: selectedEFCategory.id},
      {elementaryFlowSubCategoryId: selectedEFSubCategory.id},
      {flowDirection: selectedToggle.toUpperCase() },
    )

    fetchElementaryFlow_page(args1).then(r => {
      const res = r.payload.content[0]
      res.quantity=0
      res.originalQuantity = 0
      res.added=true
      res.customized=true
      res.paretoIncluded=false
      res.impactsContribute=0
      r.payload.content[0]=res
      setPage(r.payload);
      handleAddElementaryFlow(res)
      handleOpenAddFlowSection(undefined)
      }
    )
  }


  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <CustomAutocomplete label = {"Name"}
                            likeChangeKey = {"name"}
                            showedProperty = {"name"}
                            handleSetValue={handleChangeElementaryFlowBases}
                            fetchCall={fetchElementaryFlowBase_page}
                            triggerObject={null}
                            disabled={false}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                            {name: null},
                              {flowDirection: selectedToggle.toUpperCase() }
                            )
                            }/>
      </Grid>
      <Grid item xs={3}>
        <CustomAutocomplete label = {"Category"}
                            likeChangeKey = {"elementaryFlowCategoryName"}
                            showedProperty = {"name"}
                            handleSetValue={handleChangeEFCategory}
                            fetchCall={fetchElementaryFlowCategory_page}
                            disabled={selectedElementaryFlowBaseName? false : true}
                            triggerObject={selectedElementaryFlowBaseName}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {elementaryFlowCategoryName: null},
                              {elementaryFlowBaseName: selectedElementaryFlowBaseName ? selectedElementaryFlowBaseName : null},
                              {flowDirection: selectedToggle.toUpperCase() }
                            )
                            }/>
      </Grid>
      <Grid item xs={4}>
        <CustomAutocomplete label = {"Subcategory"}
                            likeChangeKey = {"elementaryFlowSubCategoryName"}
                            showedProperty = {"name"}
                            handleSetValue={handleChangeLinkedProcessTemplate}
                            fetchCall={fetchElementaryFlowSubCategory_page}
                            disabled={selectedEFCategory && selectedElementaryFlowBaseName? false : true}
                            triggerObject={selectedEFCategory}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {elementaryFlowSubCategoryName: null},
                              {elementaryFlowBaseName: selectedElementaryFlowBaseName ? selectedElementaryFlowBaseName : null},
                              {elementaryFlowCategoryId: selectedEFCategory ? selectedEFCategory.id : null},
                              {flowDirection: selectedToggle.toUpperCase() },
                            )
                            }/>
      </Grid>
      <Grid container item
            xs={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <IconButton aria-label="add"
                    color="primary"
                    disabled={selectedElementaryFlowBaseName && selectedEFCategory && selectedEFSubCategory? false : true}
                    onClick={handleFetchEFlow}>
          <AddBoxIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AddElementaryFlowComponent;