import ChartComponent from "./ChartComponent";

import {Grid} from "@material-ui/core";

export default function GroupAndProcessesChartsComponent({selectedData, productionLine, showGroups, showProcesses, currentMainFilter, currentIndicatorFilter}) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    {showGroups ?
                        <>
                            {!showProcesses && <Grid item xs={3}/>}
                            <Grid item xs={6}>
                                <ChartComponent
                                    data={selectedData}
                                    productionLine={productionLine}
                                    title={"Groups"}
                                    isGroup={true}
                                    showIndicator={false}
                                    currentMainFilter={currentMainFilter}
                                    currentIndicatorFilter={currentIndicatorFilter}
                                />
                            </Grid>
                        </>
                        :
                        <Grid item xs={3}/>
                    }
                    {
                        showProcesses && <Grid item xs={6}>
                            <ChartComponent
                                data={selectedData}
                                productionLine={productionLine}
                                title={"Processes"}
                                isGroup={false}
                                showIndicator={false}
                                currentMainFilter={currentMainFilter}
                                currentIndicatorFilter={currentIndicatorFilter}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}