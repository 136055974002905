import {Grid, IconButton, TextField} from "@material-ui/core";
import React, {useState} from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {fetchFlows_page, fetchUom_page} from "../../../../utils/api";
import {cloneDeep} from "lodash";
import CustomAutocomplete from "../../../shared/CustomAutocomplete";
import {v4 as uuidv4} from 'uuid';
import {sha3_256} from "js-sha3";

const args = {
  page: 0,
  size: 10,
  dir: "ASC",
  sort: "id",
}

const CreateAndChangeRPComponent = ({handleOpenChangeRPSection, processBaseId, handleChangeRPFlow}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "ASC",
      property: "id"
    },
    loaded: false
  });

  // const [selectedFlowBase, setSelectedFlowBase] = useState();
  const [selectedUom, setSelectedUom] = useState();

  const [rpName, setRpName] = useState();
  const [rpQuantity, setRpQuantity] = useState();

  // const handleChangeFlowBases = (selectedFlowBase) => {
  //   setSelectedFlowBase(selectedFlowBase);
  // }

  const handleChangeUom = (selectedUom) => {
    setSelectedUom(selectedUom);
  }

  const handleChangeRPName = (event) => {
    setRpName(event.target.value);
  }

  const handleChangeRPQuantity = (event) => {
    setRpQuantity(event.target.value);
  }

  const handleCreateRPFlow = () => {
    const newRP = {}
    //could be autogenerated during the import of the json file into the platform db
    //evaluate spring boot autogeneration or backend generation
    newRP.id = uuidv4()
    newRP.name = rpName
    newRP.flowType = "REFERENCE_PRODUCT"
    newRP.flowDirection = "OUTPUT"
    newRP.quantity = Math.abs(parseFloat(rpQuantity))
    newRP.quantityFactor = parseFloat(rpQuantity)===0 ? 1 : Math.sign(rpQuantity)
    newRP.originalQuantity = newRP.quantityFactor
    newRP.unitOfMeasureName = selectedUom.name
    newRP.impactsContribute = 0
    newRP.paretoIncluded = false
    newRP.classificationIds = []

    const flowBaseId = uuidv4();
    newRP.flowBaseId = flowBaseId
    //in future remember to create on Backend

    //in future remember to create on Backend flowTemplateId with the following convention
    // const originalFlowTemplateId = uuidv4()
    // const uprId = processBaseId + '_' + flowBaseId
    // newRP.flowTemplateId = sha3_256(uprId + '_' + originalFlowTemplateId)
    newRP.referenceFlow = true
    newRP.geographyShortName = "-"
    newRP.geographyFullName = "-"
    newRP.added = false
    newRP.removed = false
    //means rp created from scratch
    newRP.customized = true
    console.log(newRP)


    handleOpenChangeRPSection(false)
    handleChangeRPFlow(newRP)
  }


  // const handleFetchFlow = () => {
  //
  //   const args1 = Object.assign(
  //     cloneDeep(args),
  //     {size: 1},
  //     {flowBaseId: selectedFlowBase.id},
  //     {flowDirection: selectedToggle.toUpperCase()},selectedToggle
  //   )
  //
  //   fetchFlows_page(args1).then(r => {
  //       const res = r.payload.content[0]
  //       console.log(res)
  //       res.originalQuantity = 1
  //       res.quantity = 1
  //       res.flowType = "REFERENCE_PRODUCT"
  //       res.flowDirection = "OUTPUT"
  //       res.added = false
  //       res.customized = false
  //       res.paretoIncluded = false
  //       res.referenceFlow = true
  //       res.impactsContribute = 0
  //       console.log(res)
  //       r.payload.content[0] = res
  //       setPage(r.payload)
  //       handleChangeRPFlow(res, args1.flowBaseId)
  //       handleOpenChangeRPSection(false)
  //     }
  //   )
  // }


  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <TextField
          id="rp-outlined-basic"
          fullWidth
          label="New reference Product"
          value={rpName}
          onChange={handleChangeRPName}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="rp-qty-outlined-basic"
          fullWidth
          label="Quantity"
          type={"number"}
          inputProps={{lang: "en"}}
          value={rpQuantity}
          onChange={handleChangeRPQuantity}
          variant="outlined"
        />
        {/*<CustomAutocomplete label={"RP Name"}*/}
        {/*                    likeChangeKey={"name"}*/}
        {/*                    showedProperty={"name"}*/}
        {/*                    handleSetValue={handleChangeFlowBases}*/}
        {/*                    fetchCall={fetchFlowBase_page}*/}
        {/*                    triggerObject={null}*/}
        {/*                    disabled={false}*/}
        {/*                    args={Object.assign(*/}
        {/*                      cloneDeep(args),*/}
        {/*                      {sort: 'name'},*/}
        {/*                      {name: null})*/}
        {/*                    }/>*/}
      </Grid>
      <Grid item xs={2}>
        <CustomAutocomplete label={"Unit of measure"}
                            likeChangeKey={"name"}
                            showedProperty={"name"}
                            handleSetValue={handleChangeUom}
                            fetchCall={fetchUom_page}
                            triggerObject={null}
                            disabled={false}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {size: 200},
                              {name: null})
                            }/>
      </Grid>
      <Grid container item
            xs={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <IconButton aria-label="delete"
                    color="primary"
                    disabled={rpName&&rpQuantity&&selectedUom ? false : true}
                    onClick={handleCreateRPFlow}
        >
          <AddBoxIcon fontSize="large"/>
        </IconButton>
      </Grid>
      <Grid item xs={3}>
      </Grid>

      {/*<Grid item xs={12}>*/}
      {/*  <Link onClick={handleCreateRP}> >Reference product not found? Click here</Link>*/}
      {/*</Grid>*/}
    </Grid>
  )
}

export default CreateAndChangeRPComponent;