import React from "react";
import {connect} from "react-redux";
import ProcessListComponent from "../components/ProcessListComponent";
import {
  addProductionLine,
  deleteAssessments,
  modifyProcess,
  removeProcess
} from "../redux/actions/ProductionLineAction";
import {showAlert} from "../redux/actions/AlertAction";

const ProcessListContainer = props => {
  return <ProcessListComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    productionLine: state.ProductionLineReducer.productionLine,
    productionLine_isLoading: state.ProductionLineReducer.isLoading,
    productionLine_errMess: state.ProductionLineReducer.errMess,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  removeProcess: (processId) => {
    dispatch(removeProcess(processId));
  },
  modifyProcess: (selectedProcess) => {
    dispatch(modifyProcess(selectedProcess));
  },
  showAlert: (values) => {
    dispatch(showAlert(values));
  },
  deleteAssessments: () => {
    dispatch(deleteAssessments());
  },
  addProductionLine: (productionLine) => {
    dispatch(addProductionLine(productionLine));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessListContainer);
