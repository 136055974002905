import {Grid, IconButton, Link} from "@material-ui/core";
import React, {useState} from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {fetchFlowBase_page, fetchFlows_page} from "../../../../utils/api";
import {cloneDeep} from "lodash";

import CustomAutocomplete from "../../../shared/CustomAutocomplete";

const args = {
  page: 0,
  size: 10,
  dir: "ASC",
  sort: "id",
}

const ChangeRPComponent = ({handleOpenChangeRPSection, handleOpenCreateAndChangeRPSection, handleChangeRPFlow, selectedToggle}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "ASC",
      property: "id"
    },
    loaded: false
  });

  const [selectedFlowBase, setSelectedFlowBase] = useState();

  const handleChangeFlowBases = (selectedFlowBase) => {
    setSelectedFlowBase(selectedFlowBase);
  }

  const handleFetchFlow = () => {

    const args1 = Object.assign(
      //TODO
      cloneDeep(args),
      {size: 1},
      {flowBaseId: selectedFlowBase.id},
      {flowDirection: "OUTPUT"},
    )

    fetchFlows_page(args1).then(r => {
        const res = r.payload.content[0]
        console.log(res)
        res.originalQuantity = res.quantityFactor
        res.quantity = 1
        res.flowType = "REFERENCE_PRODUCT"
        res.flowDirection = "OUTPUT"
        res.added = false
        res.customized = false
        res.paretoIncluded = false
        res.referenceFlow = true
        res.impactsContribute = 0
        console.log(res)
        r.payload.content[0] = res
        setPage(r.payload)
        handleChangeRPFlow(res)
        handleOpenChangeRPSection(false)
      }
    )
  }


  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <CustomAutocomplete label={"Reference product"}
                            likeChangeKey={"name"}
                            showedProperty={"name"}
                            handleSetValue={handleChangeFlowBases}
                            fetchCall={fetchFlowBase_page}
                            triggerObject={null}
                            disabled={false}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {name: null},
                            {flowDirection: selectedToggle.toUpperCase()}
                            )
                            }/>
      </Grid>
      <Grid container item
            xs={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <IconButton aria-label="delete"
                    color="primary"
                    disabled={selectedFlowBase ? false : true}
                    onClick={handleFetchFlow}>
          <AddBoxIcon fontSize="large"/>
        </IconButton>
      </Grid>
      <Grid item xs={8}>
      </Grid>

      <Grid item xs={12}>
        <Link onClick={()=>handleOpenCreateAndChangeRPSection(true)}>Reference product not found? Click here</Link>
      </Grid>
    </Grid>
  )
}

export default ChangeRPComponent;