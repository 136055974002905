import {
  ADD_PROCESS_TEMPLATES,
  LOADING_PROCESS_TEMPLATES,
  FAILED_PROCESS_TEMPLATES,
} from "../actions/ProcessTemplateAction";

const DEFAULT_STATE = {
  isLoading: true,
  errMess: null,
  processTemplates: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_PROCESS_TEMPLATES:
      return {...state, isLoading: false, errMess: null, processTemplates: action.payload};
    case LOADING_PROCESS_TEMPLATES:
      return {...state, isLoading: true, errMess: null, processTemplates: []};
    case FAILED_PROCESS_TEMPLATES:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};
