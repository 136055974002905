import {useState} from "react";
import ChartComponent from "./ChartComponent";

import {Grid} from "@material-ui/core";

export default function IndicatorsChartsComponent({data, productionLine, showIndicator, selectedData, categoryNumber}) {

    const [currentCategory, setCurrentCategory] = useState("")

    function filterIndicatorData(field) {
        return selectedData.filter((d) => {
            return d.impactIndicatorName.includes(field)
        })
    }

    function filterCategoryDataAndTotal(field) {
        let filteredData = selectedData.filter((d) => { // first filter the category
            return d.impactCategoryName.includes(field)
        })
        return filteredData.filter((d) => { // then exclude the totals indicator
            return !d.impactIndicatorName.includes("total")
        })
    }

    function filterTotal() {
        return selectedData.filter((d) => { // then exclude the totals indicator
            return !d.impactIndicatorName.includes("total")
        })
    }

    function handleCategoryClick(e) {
        let words = e.name.split(',') // extract just the category from the string
        setCurrentCategory(words[0])
    }

    function handleResetClick() {
        setCurrentCategory("")
    }

    // return (
    //     <Grid container>
    //         <Grid item xs={12}>
    //             <Grid container>
    //                 <Grid item xs={6}>
    //                     <ChartComponent
    //                         data={filterIndicatorData("total")}
    //                         productionLine={productionLine}
    //                         onPieClick={showIndicator ? handleCategoryClick : undefined}
    //                         title={"Overview"}
    //                         isDetail={false}
    //                         showIndicator={showIndicator}
    //                     />
    //                 </Grid>
    //                 {
    //                     currentCategory !== "" ?
    //                         <Grid item xs={6}>
    //                             <ChartComponent
    //                                 data={filterCategoryDataAndTotal(currentCategory)}
    //                                 productionLine={productionLine}
    //                                 onTitleClick={showIndicator ? handleResetClick : undefined}
    //                                 title={"Detail: " + currentCategory}
    //                                 isDetail={true}
    //                                 showIndicator={showIndicator}
    //                             />
    //                         </Grid>
    //                         :
    //                         <Grid item xs={6}>
    //                             <ChartComponent
    //                                 data={filterTotal()}
    //                                 productionLine={productionLine}
    //                                 title={"Detail"}
    //                                 isDetail={true}
    //                                 showIndicator={showIndicator}
    //                             />
    //                         </Grid>
    //                 }
    //                 <Grid item xs={1}>
    //
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </Grid>
    // )

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    {categoryNumber !== 1 ?
                        <Grid item xs={6}>
                            <ChartComponent
                                data={filterIndicatorData("total")}
                                productionLine={productionLine}
                                onPieClick={showIndicator ? handleCategoryClick : undefined}
                                title={"Overview"}
                                isDetail={false}
                                showIndicator={showIndicator}
                            />
                        </Grid> :
                        <Grid item xs={3} />
                    }
                    {
                        currentCategory !== "" ?
                            <Grid item xs={6}>
                                <ChartComponent
                                    data={filterCategoryDataAndTotal(currentCategory)}
                                    productionLine={productionLine}
                                    onTitleClick={showIndicator ? handleResetClick : undefined}
                                    title={"Detail: " + currentCategory}
                                    isDetail={true}
                                    showIndicator={showIndicator}
                                />
                            </Grid>
                            :
                            <Grid item xs={6}>
                                <ChartComponent
                                    data={filterTotal()}
                                    productionLine={productionLine}
                                    title={"Detail"}
                                    isDetail={true}
                                    showIndicator={showIndicator}
                                />
                            </Grid>
                    }
                    <Grid item xs={1} />
                </Grid>
            </Grid>
        </Grid>
    )
}