import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import {v4 as uuidv4} from 'uuid';

import {toJsonObj} from "../utils/generics";

export default function FileInputComponent({
                                    onChange = () => {},
                                             label = "select file",
                                             accept = ".json",
                                             onSelected = () => {}
                                  }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [id, setId] = useState("");

  const handleFileChange = (event) => {
    let value = event.target.files[0].name
    setSelectedFile(value)
    onChange(event)
    handleFileToJson(event.target)
  }

  useEffect(() => {
    // ottengo un id univoco
    setId(uuidv4())
  }, []);

  const handleFileToJson = (fi) => {

    // const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
    // console.log(obj)


    // controllo se c'è almeno qualcosa
    if (fi.files.length > 0) {
      // se ce qualcosa dovrebbe essere 1 solo file!
      console.log(fi.files)
      const file = fi.files.item(0)

      // const read = new FileReader();
      // read.readAsBinaryString(file);
      //
      // read.onloadend = function(){
      //   if (typeof read.result === 'string') {
      //     let obj = JSON.parse(read.result)
      //     console.log(obj)
      //   }
      // }

      toJsonObj(file)
        .then(result => {
          onSelected(result) // lo mando al padre
          // console.log(result)
        })
        .catch(
          console.log
        )
    }
  }


  return (

    <div style={{borderRadius: 4, border: "1px solid #bdbdbd"}}>
      <Grid container
            alignItems={"center"}>
        <Grid item style={{borderRight: "1px solid #bdbdbd"}}>
          <input
            accept={accept}
            style={{display: 'none'}}
            id={id}
            onChange={handleFileChange}
            type="file"
          />
          <label style={{height: "100%"}} htmlFor={id}>
            <Button style={{height: "100%"}} component="span">
              {label}
            </Button>
          </label>
        </Grid>
        <Grid item>
          <Typography variant="body2" style={{color: "#626262", marginLeft: 16}}>
            {selectedFile}
          </Typography>
        </Grid>
      </Grid>


    </div>

  )

}
