import {Grid, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  fetchFlows_page,
  fetchGeographies_page,
  fetchMixFlowBase_page,
  fetchProcessTemplates_page
} from "../../../../utils/api";
import {cloneDeep} from "lodash";

import CustomAutocomplete from "../../../shared/CustomAutocomplete";

const args = {
  page: 0,
  size: 10,
  dir: "ASC",
  sort: "id",
}

const AddMixFlowComponent = ({handleOpenAddFlowSection, selectedToggle, handleAddMixFlow}) => {

  const [page, setPage] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: {
      direction: "ASC",
      property: "id"
    },
    loaded: false
  });


  const [selectedFlowBase, setSelectedFlowBase] = useState();
  const [selectedGeography, setSelectedGeography] = useState();
  const [selectedProcessTemplate, setSelectedProcessTemplate] = useState();

  const handleChangeFlowBases = (selectedFlowBase) => {
    setSelectedFlowBase(selectedFlowBase);
  }

  const handleChangeGeographies = (selectedGeography) => {
    setSelectedGeography(selectedGeography);
  }

  const handleChangeLinkedProcessTemplate = (selectedProcessTemplate) => {
    setSelectedProcessTemplate(selectedProcessTemplate);
  }

  const handleFetchFlow = () => {

    const args1 = Object.assign(
      cloneDeep(args),
      {size: 1},
      {processTemplateId: selectedProcessTemplate.id},
      {flowDirection: selectedToggle.toUpperCase()},
    )

    fetchFlows_page(args1).then(r => {
        const res = r.payload.content[0]
        res.quantity = 0
        res.originalQuantity = 0
        res.added = true
        res.customized = true
        res.paretoIncluded = false
        res.impactsContribute = 0
        r.payload.content[0] = res
        setPage(r.payload)
        handleAddMixFlow(res);
        handleOpenAddFlowSection(undefined)
      }
    )
  }


  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <CustomAutocomplete label={"Name"}
                            likeChangeKey={"name"}
                            showedProperty={"name"}
                            handleSetValue={handleChangeFlowBases}
                            fetchCall={fetchMixFlowBase_page}
                            triggerObject={null}
                            disabled={false}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'name'},
                              {name: null},
                              {type: 'electricity'},
                              {uomId: 'a2e8e9a8750700a7fb90c42b7e14111cfb439fff21e9d5d6c460b3c7b0635a45'},
                              {flowDirection: selectedToggle.toUpperCase()}
                              )
                            }/>
      </Grid>
      <Grid item xs={3}>
        <CustomAutocomplete label={"Geography"}
                            likeChangeKey={"shortName"}
                            showedProperty={"shortName"}
                            handleSetValue={handleChangeGeographies}
                            fetchCall={fetchGeographies_page}
                            disabled={selectedFlowBase ? false : true}
                            triggerObject={selectedFlowBase}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'shortName'},
                              {shortName: null},
                              {flowBaseId: selectedFlowBase ? selectedFlowBase.id : null},
                              {flowDirection: selectedToggle.toUpperCase()}
                            )
                            }/>
      </Grid>
      <Grid item xs={4}>
        <CustomAutocomplete label={"Process"}
                            likeChangeKey={"processBaseName"}
                            showedProperty={"processBaseName"}
                            handleSetValue={handleChangeLinkedProcessTemplate}
                            fetchCall={fetchProcessTemplates_page}
                            disabled={selectedGeography && selectedFlowBase ? false : true}
                            triggerObject={selectedGeography}
                            args={Object.assign(
                              cloneDeep(args),
                              {sort: 'processBase.name'},
                              {processBaseName: null},
                              //check if selectedFlowBase different from null and undefined
                              {flowBaseId: selectedFlowBase ? selectedFlowBase.id : null},
                              {flowDirection: selectedToggle.toUpperCase()},
                              {geographyId: selectedGeography ? selectedGeography.id : null},
                            )
                            }/>
      </Grid>
      <Grid container item
            xs={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <IconButton aria-label="delete"
                    color="primary"
                    disabled={selectedGeography && selectedFlowBase && selectedProcessTemplate ? false : true}
                    onClick={handleFetchFlow}>
          <AddBoxIcon fontSize="large"/>
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AddMixFlowComponent;