import {
  LOADING_ASSESSMENT,
  FAILED_ASSESSMENT,
} from "../actions/ActionType";

const DEFAULT_STATE = {
  isLoading: false,
  errMess: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOADING_ASSESSMENT:
      return {...state, isLoading: action.payload, errMess: null};
    case FAILED_ASSESSMENT:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};
