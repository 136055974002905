import React from "react";
import {connect} from "react-redux";
import MainComponent from "../components/MainComponent";
import {hideAlert} from "../redux/actions/AlertAction";

const MainContainer = props => {
  return <MainComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    alert: state.AlertReducer,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  hideAlert: () => {
    dispatch(hideAlert());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainContainer);
