import React, {useEffect, useState, useRef} from "react";
import IndicatorsChartsComponent from "./IndicatorsChartsComponent";
import GroupAndProcessesChartsComponent from "./GroupAndProcessesChartsComponent";
import FilterComponent from "./FilterComponent";

import {Button, Container, Paper} from "@material-ui/core";
import html2canvas from "html2canvas";

export default function ImpactChartTabComponent({data, productionLine, isProductPage, categoryNumber}) {

    const defaultMainFilter = {id: "All", name: "All", type: "ALL"}
    const defaultIndicatorFilter = {id: "", name: "All"}
    const [currentMainFilter, setCurrentMainFilter] = useState(defaultMainFilter)
    const [currentIndicatorFilter, setCurrentIndicatorFilter] = useState(defaultIndicatorFilter)
    const [selectedData, setSelectedData] = useState(data)
    const [groupAndProcessData, setGroupAndProcessData] = useState(selectedData)
    const [showGroups, setShowGroups] = useState(true)
    const [showProcesses, setShowProcesses] = useState(true)
    const elementRef = useRef();

    useEffect(() => {
        setSelectedData(data)
        setCurrentMainFilter(defaultMainFilter)
        setCurrentIndicatorFilter(defaultIndicatorFilter)
        setShowGroups(true)
        setShowProcesses(true)
    }, [productionLine]);

    function checkUnitOfMeasure(isIndicatorGraph) {
        if (selectedData.length === 0)
            return false;
        if (isIndicatorGraph) {
            let unitOfMeasure = selectedData[0].unitOfMeasureName
            for (let i = 0; i < selectedData.length; i++) {
                if (selectedData[i].unitOfMeasureName !== unitOfMeasure)
                    return false;
            }
            return true;
        } else {
            let unitOfMeasure = groupAndProcessData[0].unitOfMeasureName
            for (let i = 0; i < groupAndProcessData.length; i++) {
                if (groupAndProcessData[i].unitOfMeasureName !== unitOfMeasure)
                    return false;
            }
            return true;
        }
    }

    function handleFilteredItem(item, isMainFilter) {
        if (isMainFilter) {
            setCurrentMainFilter(item)
            switch (item.type) {
                case "ALL":
                    setSelectedData(productionLine.assessments[0].environmentalImpacts)
                    setShowGroups(true)
                    setShowProcesses(true)
                    break;
                case "GROUP":
                    setSelectedData(productionLine.processes.find(p => p.group === item.name).assessments[0].environmentalImpacts)
                    setShowGroups(false)
                    setShowProcesses(true)
                    break;
                case "PROCESS":
                    setSelectedData(productionLine.processes.find(p => p.id === item.id).assessments[0].environmentalImpacts)
                    setShowGroups(true)
                    setShowProcesses(false)
            }
        } else {
            // handleFilteredItem(currentMainFilter, true)
            setCurrentIndicatorFilter(item)
            if (item.id !== '') {
                const ddata = [selectedData.find(d => d.impactIndicatorId === item.id)]
                setGroupAndProcessData(ddata)
            } else {
                setGroupAndProcessData(selectedData)
            }
        }
    }

    function getMainListItem() {
        const set = new Set()
        set.add(JSON.stringify({
            id: 'All',
            name: 'All',
            type: "ALL"
        }))
        productionLine.processes.map(p => { // done in 2 steps for keeping groups on top
            if (p.group !== undefined && p.group !== "") {
                set.add(JSON.stringify({
                    name: p.group,
                    type: "GROUP"
                }))
            }
        })
        productionLine.processes.map(p => {
            if (p.name === undefined || p.name === "") {
                set.add(JSON.stringify({
                    id: p.id,
                    name: p.processBaseName,
                    type: "PROCESS"
                }))
            } else {
                set.add(JSON.stringify({
                    id: p.id,
                    name: p.name,
                    type: "PROCESS"
                }))
            }
        })
        const formattedSet = [...set].map((item) => { // needed for ensuring unique elements
            if (typeof item === 'string') return JSON.parse(item);
            else if (typeof item === 'object') return item;
        })
        return Array.from(formattedSet)
    }

    function getAcronym(str) {
        str = str.toUpperCase()
        let matches = str.match(/\b(\w)/g);
        return matches.join('');
    }

    function getIndicatorListItem() {
        let list = []
        list.push(defaultIndicatorFilter)
        selectedData.map((d) => {
            list.push({
                id: d.impactIndicatorId,
                name: `(${getAcronym(d.impactCategoryName)}) ${d.impactIndicatorName}`
            })
        })
        return list
    }

    const handleExportCharts = () => {

        html2canvas(elementRef.current).then(canvas => {
            const image = canvas.toDataURL('image/png', 1)

            const a = document.createElement('a');
            a.download = "ImpactsCharts.png";
            a.href = image;
            a.click();
        });
    }

    return (
        <>
            <Container
                component={isProductPage === true ? Paper : 'div'}
                maxWidth={false}
                ref={elementRef}
            >
                {selectedData.length !== 0 && selectedData.length !== undefined  &&
                    <>
                        <FilterComponent
                            items={getMainListItem()}
                            isMainFilter={true}
                            handleFilteredItem={handleFilteredItem}
                            currentFilter={currentMainFilter}
                            title={"Filter by Group and Processes:"}
                        />
                        {checkUnitOfMeasure(true) && <IndicatorsChartsComponent
                            data={data}
                            productionLine={productionLine}
                            showIndicator={true}
                            selectedData={selectedData}
                            categoryNumber={categoryNumber}
                        />}
                        <FilterComponent
                            items={getIndicatorListItem()}
                            isMainFilter={false}
                            handleFilteredItem={handleFilteredItem}
                            currentFilter={currentIndicatorFilter}
                            title={"Filter by Indicators:"}
                        />
                        {checkUnitOfMeasure(false) ? <GroupAndProcessesChartsComponent
                                selectedData={groupAndProcessData}
                                productionLine={productionLine}
                                showGroups={showGroups}
                                showProcesses={showProcesses}
                                currentMainFilter={currentMainFilter}
                                currentIndicatorFilter={currentIndicatorFilter}
                            /> :
                            <br/>}
                    </>
                }
            </Container>
            {selectedData.length !== 0 && selectedData.length !== undefined &&
                <Button variant="text" color={"primary"} onClick={() => handleExportCharts()}>
                    Export charts
                </Button>
            }
        </>
    )
}