import React, {useEffect, useState} from 'react';
// import {Dimmer, Loader, Menu, Pagination} from 'semantic-ui-react'
import SearchIcon from '@material-ui/icons/Search';
import { createFilter } from 'javascript-search-input';


import {
  alpha,
  CircularProgress,
  InputBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 0),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));


const useFilter = ({ keys, data }) => {
  const [inputText, setInputText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(inputText);
  const [filtered, setFiltered] = useState(data);

  useEffect(() => {
    setFiltered(data)
  }, [data]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchText(inputText);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [inputText]);

  useEffect(() => {
    const myFilter = createFilter(keys);
    const filt= data.filter(myFilter(inputText));
    setFiltered(filt)
  }, [debouncedSearchText]);

  return { inputText, setInputText, filtered };
};


const AddProcessComponent = props => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!props.processTemplates_isLoading) {
      setData(props.processTemplates)
    }
  }, [props.processTemplates]);

  const { inputText, setInputText, filtered } = useFilter({
    keys: ["processBaseName", "referenceProductName", "locationArea"],
    data: data,
  });

  // const [searchText, setSearchText] = useState("");
  // const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  // const [paginationNumber, setPaginationNumber] = useState(1);
  const [selectedProcessId, setSelectedProcessId] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const {fetchProcessTemplate} = props.;
  useEffect(() => {
    props.onProcessSelected(selectedProcessId);
  }, [selectedProcessId]);

  useEffect(() => {
    props.fetchProcessTemplate()
  }, []);

  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setDebouncedSearchText(searchText);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, [searchText]);

  // useEffect(() => {
  //   if (props.processTemplates !== undefined || props.processTemplates.length===0) {
  //     {
  //       filterData(debouncedSearchText)
  //     }
  //   }
  // }, [debouncedSearchText]);



// exclude column list from filter
//   const excludeColumns = ["id", "color"];
  const includedKeys = ["processBaseName", "referenceProductName", "locationArea"];
//   const includedKeys = ["name", "year"];

// handle change event of search input
//   const handleChange = value => {
//     setSearchText(value);
//     filterData(value);
//   };

  // const handleAddSelectedProject = () => {
  //   // console.log(selectedProcessId);
  //   props.fetchProcessAndAddToProductionLine(selectedProcessId);
  // };

  // filter records by search text
  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "") {
  //     setData(props.processTemplates);
  //   } else {
  //     const filteredData = props.processTemplates.filter(item => {
  //       let listOfWord = [];
  //       for (const eachKey of includedKeys) {
  //         listOfWord = listOfWord.concat(item[eachKey].toString().toLowerCase().split(" "));
  //         console.log(listOfWord)
  //       }
  //       return lowercasedValue.split(" ").every(word => listOfWord.includes(word));
  //       // Object.keys(item).some(key =>
  //       //   includedKeys.includes(key) ? item[key].toString().toLowerCase().split(" ").some(word => lowercasedValue.split(" ").includes(word)) : false
  //       // );
  //     });
  //     setData(filteredData);
  //   }
  // }

  const searchBar = () => {
    return (
      <div className="defaultPadding">
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon/>
          </div>
          <InputBase
            placeholder="Search a new process...…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{width: "100%"}}
            inputProps={{'aria-label': 'search'}}
            value={inputText}
            onChange={e => setInputText(e.target.value)}
          />
        </div>
      </div>


      /*    <div className="defaultPadding">
            <div className="ui icon input">
              <input
                // style={{marginLeft: 5}}
                type="text"
                placeholder="Search a new process..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
              <i aria-hidden="true" className="search icon"></i>
            </div>
          </div>*/

    )
      ;
  }

  const pTListTable = (tableHeaders) => {
    return (
      <div className="defaultPadding">
        <TableContainer >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader, index) => (
                  <TableCell key={index}>{tableHeader}</TableCell>))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                  ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : filtered
              ).map((d) => {
              // {data.slice((paginationNumber - 1) * 5, (paginationNumber - 1) * 5 + 5).map((d, index) => {
                return <TableRow
                  key={d.id}
                  style={{
                    background: selectedProcessId === d.id ? 'rgba(34,36,38,.1)' : null
                  }}
                  onClick={() => setSelectedProcessId(d.id)}
                >
                  <TableCell>{d.processBaseName}</TableCell>
                  <TableCell>{d.locationArea}</TableCell>
                  <TableCell>{d.referenceProductName}</TableCell>
                </TableRow>
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={filtered.length === 0 ? 1 : filtered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // SelectProps={{
                  //   inputProps: { 'aria-label': 'rows per page' },
                  //   native: true,
                  // }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="Process" style={{width: "100%", height: "100%"}}>
        {props.processTemplates_isLoading ?
          <CircularProgress size={24}/>
          :
          null}
        {props.processTemplates_isLoading ? null : searchBar()}
        {props.processTemplates_isLoading ? null : pTListTable(["Process", "Geography", "Reference Product"])}
        {/*{debouncedSearchText==="" ? null : pTListTable(paginationNumber)}*/}
      </div>
      {/*      <div style={{width: "50%", height: "100%"}}>
      </div>
      PageOne
      <a href="/pagetwo">Navigate to Page Two, bad approach</a>
      <Link to="/production-line">Navigate to ProductionLineComponent</Link>*/}
    </React.Fragment>
  );
};

export default AddProcessComponent;