import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import ImpactsTableComponent from "./shared/ImpactsTableComponent";
import ImpactTabsComponent from "./ImpactTabsComponent"

const ImpactListComponent = ({
                                 productionLine,
                                 productionLine_isLoading,
                                 fetchMethodologies,
                                 methodologies,
                                 calculateProductionLineAssessment,
                                 assessments_isLoading,
                             }) => {

    // const [searchText, setSearchText] = useState("");
    // const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [data, setData] = useState(productionLine);
    const [openAddProcessModal, setOpenAddProcessModal] = React.useState(false)
    const [openEditProcessModal, setOpenEditProcessModal] = React.useState(false)
    const [selectedProcessId, setSelectedProcessId] = React.useState(false)
    const [selectedMethodology, setSelectedMethodology] = React.useState(null)


    useEffect(() => {
        fetchMethodologies()
    }, []);

    useEffect(() => {
        if (methodologies.length !== 0) {
            setSelectedMethodology(methodologies.find(e => e.id === "679a3f0b-79df-4527-af9e-9ff901114715"))
        }
    }, [methodologies]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMethodologyChange = (value) => {
        setSelectedMethodology(value)
    };

    const handleCalculate = () => {
        calculateProductionLineAssessment(selectedMethodology)
    };

    // const handleSelectMethodologyChange = (event) => {
    //   const name = event.target.name;
    //   setState({
    //     ...state,
    //     [name]: event.target.value,
    //   });
    // };

    // const handleOpenEditProcessModal = id => {
    //   setSelectedProcessId(id)
    //   setOpenEditProcessModal(true)
    // };

    // const processListTable = (tableHeaders) => {
    //   return (
    //       <TableContainer component={Paper}>
    //         <Table size="small" aria-label="a dense table">
    //           <TableHead>
    //             <TableRow>
    //               {tableHeaders.map((tableHeader, index) => (
    //                 <TableCell key={index}>{tableHeader}</TableCell>))}
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {(rowsPerPage > 0
    //                 ? data.processes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //                 : data.processes
    //             ).map((d) => {
    //               return <TableRow
    //                 key={d.id}
    //                 // onClick={() => handleOpenEditProcessModal(d.id)}
    //               >
    //                 <TableCell><ClearIcon style={{verticalAlign: "bottom"}} />{d.processBaseName}</TableCell>
    //                 <TableCell>{d.locationAreaName}</TableCell>
    //                 <TableCell>{d.referenceProductName}</TableCell>
    //               </TableRow>
    //             })}
    //           </TableBody>
    //           <TableFooter>
    //             <TableRow>
    //               <TableCell>
    //               </TableCell>
    //               <TablePagination
    //                 rowsPerPageOptions={[5, 10, 25]}
    //                 colSpan={3}
    //                 count={data.processes.length === 0 ? 1 : data.processes.length}
    //                 rowsPerPage={rowsPerPage}
    //                 page={page}
    //                 // SelectProps={{
    //                 //   inputProps: { 'aria-label': 'rows per page' },
    //                 //   native: true,
    //                 // }}
    //                 onPageChange={handleChangePage}
    //                 onRowsPerPageChange={handleChangeRowsPerPage}
    //                 // ActionsComponent={TablePaginationActions}
    //               />
    //             </TableRow>
    //           </TableFooter>
    //         </Table>
    //       </TableContainer>
    //   );
    // }
    const isCalculateActive = !(productionLine.processes.length === 0 ||
        productionLine.processes.length === undefined ||
        selectedMethodology === null ||
        assessments_isLoading)

    return (
        <Grid container spacing={1}>
            {/*<AddProcessModalContainer*/}
            {/*  open={openAddProcessModal}*/}
            {/*  onClose={() => setOpenAddProcessModal(false)}*/}
            {/*  onOpen={() => setOpenAddProcessModal(true)}*/}
            {/*/>*/}
            {/*<EditProcessModalContainer*/}
            {/*  open={openEditProcessModal}*/}
            {/*  onClose={() => setOpenEditProcessModal(false)}*/}
            {/*  onOpen={() => setOpenEditProcessModal(true)}*/}
            {/*  selectedProcessId={selectedProcessId}*/}
            {/*/>*/}

            <Grid item xs={3}>
                <Autocomplete
                    fullWidth
                    options={methodologies}
                    value={selectedMethodology}
                    onChange={((event, value) => handleMethodologyChange(value))}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) =>
                        <TextField
                            {...params} label="Methodology"
                            variant="outlined"/>}
                />
            </Grid>

            {/*<MySelect*/}
            {/*  label="Methodology:"*/}
            {/*  options={methodologies}*/}
            {/*  onEdit={}*/}
            {/*/>*/}

            {/*<SelectEntry*/}
            {/*  label="Assembly"*/}
            {/*  visible={fieldsVisibility["assembly"]}*/}
            {/*  value={assembly}*/}
            {/*  options={assemblies}*/}
            {/*  onEdit={(assembly) => {*/}
            {/*    setAssembly(assembly);*/}
            {/*    handleValueChange("assembly", assembly.id)*/}
            {/*  }}*/}
            {/*  editable={edit}/>*/}
            <Grid item xs={9}>
                <Button style={{height: "100%"}} variant={"contained"}
                        disabled={isCalculateActive ? false : true}
                        color={"primary"}
                    // color={isCalculateActive ? "primary" : "secondary"}
                        onClick={handleCalculate}>Calculate</Button>
            </Grid>


            <Grid item xs={12}>
                {assessments_isLoading ? <>
                        <CircularProgress size={24}/>
                        <p>The calculation can take up to minutes depending on the quantity of processes specified and their
                            number of flows</p>
                    </>
                    :
                    null}
                {assessments_isLoading ? null :
                    <>
                        <ImpactTabsComponent title="Assessment:"
                                             data={productionLine.assessments[0].environmentalImpacts !== undefined ? productionLine.assessments[0].environmentalImpacts : []}
                                             productionLine={productionLine}
                        />
                    </>

                }
            </Grid>

            {/*<Grid item xs={12}>*/}
            {/*  <Button variant="contained" color="primary" onClick={() => setOpenAddProcessModal(true)}>Add new*/}
            {/*    process</Button>*/}
            {/*</Grid>*/}
        </Grid>
    );
};

export default ImpactListComponent;