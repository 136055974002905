import React from "react";
import {connect} from "react-redux";
import ProductionLineComponent from "../components/ProductionLineComponent";

const ProductionLineContainer = props => {
  return <ProductionLineComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    // language: state.LanguageReducer.language,
    // width: state.ScreenReducer.width,
    // height: state.ScreenReducer.height,
    // view: state.ViewReducer.view,
    ...ownProps,
  };
};

// const mapDispatchToProps = dispatch => (
//   {
//   changeScreenSize: (width, height) => {
//     dispatch(changeScreenSize(width, height));
//   },
// }
// );

export default connect(
  mapStateToProps,null
  // mapDispatchToProps
)(ProductionLineContainer);
