import React from 'react';
import ProcessContainer from "../../../containers/dialogs/addProcess/AddProcessContainer";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

const AddProcessModalComponent = (props) => {
  // const [open, setOpen] = React.useState(false)
  const [selectedProcessId, setSelectedProcessId] = React.useState("")


  const handleAddSelectedProcess = () => {
    // console.log(selectedProcessId);
    // console.log(selectedProcessId);
    props.fetchProcessAndAddToProductionLine(selectedProcessId);
    props.deleteAssessments();
    props.onClose();
  };

  const onProcessSelected = (id) => {
    // console.log(selectedProcessId);
    // props.fetchProcessAndAddToProductionLine(selectedProcessId);
    setSelectedProcessId(id);
  };

  const isAddSelectedProcessDisabled = selectedProcessId === ""? true : false

  return (
    <Dialog className="AddProcessModalComponent"
           onClose={props.onClose}
           open={props.open}
           aria-labelledby="simple-modal-title"
            fullWidth maxWidth={"xl"}
      // onClose={() => setOpen(false)}
      // onOpen={() => setOpen(true)}
      // open={open}
      // trigger={<Button>Add new process</Button>}
    >
      <DialogTitle id="max-width-dialog-title">Add new process</DialogTitle>

      <DialogContent>
        <ProcessContainer onProcessSelected={onProcessSelected}/>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={props.onClose}>Cancel</Button>
        <Button color="primary" variant={"contained"} disabled={isAddSelectedProcessDisabled} onClick={handleAddSelectedProcess}>Add selected process</Button>

      </DialogActions>
    </Dialog>
  );
};

export default AddProcessModalComponent;
