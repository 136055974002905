import React from "react";
import {
    Tabs,
    Tab,
    Box,
    Typography,
    Grid
} from "@material-ui/core";
import PropTypes from 'prop-types';
import ImpactsTableComponent from "./shared/ImpactsTableComponent";
import ImpactChartsTabComponent from "./shared/ImpactChartsTabComponent";
import _ from "lodash";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}} style={{paddingRight: '0', paddingLeft: '0'}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ImpactTabsComponent({title, data, productionLine}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function checkUnitOfMeasure(categoryData) {
        if (categoryData.length === 0)
            return false;
        let unitOfMeasure = categoryData[0].unitOfMeasureName
        for (let i = 0; i < categoryData.length; i++) {
            if (categoryData[i].unitOfMeasureName !== unitOfMeasure)
                return false;
        }
        return true;

    }

    /**
     * Given the data objects array it computes the missing total indicators for each category
     * @returns {*[]|*} new data array
     */
    function computeMissingData() {
        const dataCopy = _.cloneDeep(data);
        if (dataCopy === undefined)
            return []
        else {
            let categories = dataCopy.map(function (d) {
                return d.impactCategoryName;
            });
            let uniqueCategories = [...new Set(categories)]
            uniqueCategories.forEach(cat => {
                let categoryData = dataCopy.filter((d) => {
                    return d.impactCategoryName === cat
                })
                let totalData = categoryData.filter((d) => {
                    return d.impactIndicatorName.includes("total")
                })
                if (totalData.length === 0 && checkUnitOfMeasure(categoryData)) { // if there's not a total indicator for this category and unit of measure is equal
                    let categoryTotalValue = 0
                    categoryData.forEach((d) => {
                        categoryTotalValue += d.value
                    })
                    let newDataObject = {
                        impactCategoryId: "",
                        impactCategoryName: cat,
                        impactIndicatorId: "",
                        impactIndicatorName: "total",
                        methodologyId: categoryData[0].methodologyId,
                        methodologyName: categoryData[0].methodologyName,
                        unitOfMeasureName: categoryData[0].unitOfMeasureName,
                        unitValue: categoryData[0].unitValue,
                        value: categoryTotalValue
                    }
                    dataCopy.push(newDataObject)
                }
            })
            return dataCopy
        }
    }

    function computeCategoryNumber() {
        if (data === undefined)
            return 0
        else {
            let categories = data.map(function (d) {
                return d.impactCategoryName;
            });
            let uniqueCategories = [...new Set(categories)]
            return uniqueCategories.length
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} textColor="primary"
                                  indicatorColor="primary" aria-label="impact tabs" centered>
                                <Tab label="Table" {...a11yProps(0)} />
                                <Tab label="Charts" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <ImpactsTableComponent
                                data={data}
                                productionLine={productionLine}
                                tableHeaders={["Category", "Indicator", "Value"]}
                                isProductPage={true}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ImpactChartsTabComponent
                                data={computeMissingData()}
                                productionLine={productionLine}
                                isProductPage={true}
                                categoryNumber={computeCategoryNumber()}
                            />
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}