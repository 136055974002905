// eslint-disable-next-line no-unused-vars
import {
    contextPath,
    generalApi,
    ineditManufacturerApi,
    ineditPlatformLCADataApi,
    ineditPlatformLCAImpactDataApi,
    ineditLoginApi,
    manufacturerProcessesUrl,
    lcaDataUrl
} from "../shared/baseUrls";

// const blobCache = {}

export let lcaDataAlreadyExists = false

// general https request
const performCall = (path, queryParams, headers, method, externalCall = false, body, propertyCheck, ignoreResult = false) => {

    if (queryParams !== null && queryParams !== "") {
        let queryParamsArr = Object.keys(queryParams).filter(i => queryParams[i] !== null).map(k => k + "=" + queryParams[k])
        if (queryParamsArr !== null) {
            path += '?' + queryParamsArr.join('&')
        }
    }

    if (body !== null) {
        body = JSON.stringify(body)
    }

    // ritorno una promise
    return new Promise((resolve, reject) => {

        let context = contextPath
        if (externalCall) {
            context = ''
        }
        fetch(context + path, {
            headers: headers,
            body: body,
            method: method
        })
            .then(response => {
                    console.log(response)
                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400) {
                        // converto il risultato in oggetto js (json)
                        if (!ignoreResult)
                            response.json().then(json => {
                                if (!propertyCheck || json.hasOwnProperty(propertyCheck)) {
                                    console.log(json)
                                    resolve(json)
                                } else
                                    reject({message: "Missing prop " + propertyCheck})
                            })
                        else resolve()
                        // altrimenti passo da qui e ritorno errore
                    } else {
                        response.json()
                            .then(json => {
                                console.log(json)
                                reject(json)
                            })
                            .catch(() => reject({message: "error unknown"}))
                    }
                }
            )
    })
}

// export const authenticate = (email, password) => {
//     return performCall(
//         API + `/administrator/authenticate`,
//         {'Content-Type': 'application/json'},
//         "POST",
//         {email: email, password: password},
//         "payload"
//     )
// }

//flow add
export function fetchFlowBase_page({name, flowDirection, page, size, sortDir, sortField}) {
    return performCall(
        '/api' + '/sust' + generalApi + `/flowBases/page`,
        arguments[0],
        {},
        "get"
    )
}

//mix-flow add
export function fetchMixFlowBase_page({name, type, uomId, flowDirection, page, size, sortDir, sortField}) {
    return performCall(
        '/api' + '/sust' + generalApi + `/flowBases/page`,
        arguments[0],
        {},
        "get"
    )
}


export function fetchGeographies_page(
    {shortName, flowBaseId, flowDirection, page, size, sortDir, sortField}
) {
    //      ?shortName=${shortName}&flowBaseId=${flowBaseId}&flowDirection=${flowDirection}&size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,

    // console.log(url)
    return performCall(
        '/api' + '/sust' + generalApi + `/geographies/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchProcessTemplates_page(
    {processBaseName, flowBaseId, flowDirection, geographyId, page, size, sortDir, sortField}
) {
    //      ?shortName=${shortName}&flowBaseId=${flowBaseId}&flowDirection=${flowDirection}&size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,

    return performCall(
        '/api' + '/sust' + generalApi + `/processTemplates/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchFlows_page(
    {flowBaseId, flowDirection, processTemplateId, page, size, sortDir, sortField}
) {
    //      ?shortName=${shortName}&flowBaseId=${flowBaseId}&flowDirection=${flowDirection}&size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,

    return performCall(
        '/api' + '/app' + generalApi + `/flows/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchRPFlows_page(
    {flowBaseId, processTemplateId, page, size, sortDir, sortField}
) {
    //      ?shortName=${shortName}&flowBaseId=${flowBaseId}&flowDirection=${flowDirection}&size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,

    return performCall(
        '/api' + '/app' + generalApi + `/flows/rp/page`,
        arguments[0],
        {},
        "get"
    )
}

//elementary Flow add
export function fetchElementaryFlowBase_page({name, flowDirection, page, size, sortDir, sortField}) {
    return performCall(
        '/api' + '/sust' + generalApi + `/elementaryFlowBases/distinct-name/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchElementaryFlowCategory_page(
    {elementaryFlowBaseName, elementaryFlowCategoryName, flowDirection, page, size, sortDir, sortField}
) {
    return performCall(
        '/api' + '/sust' + generalApi + `/elementaryFlowCategory/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchElementaryFlowSubCategory_page(
    {
        elementaryFlowBaseName,
        elementaryFlowCategoryId,
        elementaryFlowSubCategoryName,
        flowDirection,
        page,
        size,
        sortDir,
        sortField
    }
) {
    return performCall(
        '/api' + '/sust' + generalApi + `/elementaryFlowSubCategory/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchElementaryFlow_page(
    {
        elementaryFlowBaseName,
        elementaryFlowCategoryId,
        elementaryFlowSubCategoryId,
        flowDirection,
        page,
        size,
        sortDir,
        sortField
    }
) {
    return performCall(
        '/api' + '/app' + generalApi + `/elementaryFlows/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchUom_page(
    {name, page, size, sortDir, sortField}
) {
    return performCall(
        '/api' + '/sust' + generalApi + `/unitOfMeasures/page`,
        arguments[0],
        {},
        "get"
    )
}

export function fetchModel() {
    // console.log(modelID);
    return performCall(
        '/egi-api' + window._env_.MODEL_PATH + window._env_.MODEL_FILENAME,
        // '/egi-api/oneprovider/data/' + window._env_.MODEL + '/content',
        {},
        {'X-Auth-Token': 'MDAxY2xvY2F00aW9uIGRhdGFodWIuZWdpLmV1CjAwNmJpZGVudGlmaWVyIDIvbm1kL3Vzci1jYTE4MTgzYjMwYmI5NmVlODUwY2U1YTRiODIzOWZkM2NoYmI5Ni9hY3QvNDIyZTg1ZTNmZTcyMDBkZGEyOGU3MWI00M2Q3NmQ3OWRjaDNlNTQKMDAxOWNpZCBpbnRlcmZhY2UgPSByZXN00CjAwMThjaWQgc2VydmljZSA9IG9wdy00qCjAwNjFjaWQgZGF00YS5wYXRoID00gTHpVeFlURmlOakkwT00RkbU5EaGlNamRqTWpNek56UTJZVEUwT00dJNE1qRmpZMmd4WlRnMkwwUkpSMGxVUW5KaGFXNHZjM1Z3YzJrPQowMDJmc2lnbmF00dXJlIF00A1bal8g64vaLVmdcelQA9u4TSsqZjPcvZjvkCPGY02Cg'},
        "get",
        true
    )
}

/***** INEDIT *****/
function loginToIneditPlatform() {
    return performCall(
        '' + ineditLoginApi,
        null,
        {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Content-type': 'application/json',
            'token': 'CDltuIGq4wfATvBbPBzaH+skfNjgaL5iLo5wd5iFNVzY9ivNwR8qSdcVePVRFto2VDzxWabygjr34P55g4DkAw=='
        },
        "post",
        true,
        JSON.parse("{\"email\":\"sdo-admin@fanvoice.com\", \"password\":\"Sdo@serVice22/\" , \"provider\":\"internal\"}")
    )
}

export function fetchModelFromIneditPlatform(manufacturerId) {
    return new Promise((resolve, reject) => {
        performCall(
            manufacturerProcessesUrl + manufacturerId + lcaDataUrl,
            null,
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Content-type': 'application/json',
            },
            "get",
            true
        ).then(resolve).catch(reject)
    });
}

export function setLcaDataAlreadyExists(lcaDataExists) {
    lcaDataAlreadyExists = lcaDataExists
}

export function saveModelToIneditPlatform(manufacturerId, productionLine) {
    //console.log(JSON.stringify(productionLine))
    return new Promise((resolve, reject) => {
        performCall(
            manufacturerProcessesUrl + manufacturerId + lcaDataUrl,
            {'exists': lcaDataAlreadyExists},
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Content-type': 'application/json',
            },
            "post",
            true,
            productionLine
        ).then(resolve).catch(reject)

    });
}