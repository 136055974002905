import React from 'react';
import '../styles/Footer.css';
import {logoUrl} from "../shared/baseUrls";
import {Grid} from "@material-ui/core";

const Footer = () => {
  return (
    <div className="Footer">
        <div style={{float: "left", color : "white", padding:"15px", width:"85%"}}>The platform is resulting from the work carried out by projects that received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreements N° 869952, 869884. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union. Neither the European Union nor the granting authority can be held responsible for them.</div>
        <div style={{float: "right",padding:"7px", width:"15%"}}><img src={logoUrl + 'logo_eu.png'} alt="SUPSI logo" width={"100%"}/></div>
{/*      <Breadcrumb>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>Store</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>T-Shirt</Breadcrumb.Section>
      </Breadcrumb>*/}
    </div>
  );
};

export default Footer;
