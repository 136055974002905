import { processTemplateUrl} from '../../shared/baseUrls';

export const ADD_PROCESS_TEMPLATES= 'ADD_PROCESS_TEMPLATES';
export const LOADING_PROCESS_TEMPLATES = 'LOADING_PROCESS_TEMPLATES';
export const FAILED_PROCESS_TEMPLATES = 'FAILED_PROCESS_TEMPLATES';

export const fetchProcessTemplate = () => dispatch => {
  dispatch(loadingProcessTemplates(true));

  return fetch(processTemplateUrl,
    // {mode:"no-cors"}
    )
    .then(
      response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => response.payload)
    .then(processTemplates => {
      // console.log(processTemplates);
      return dispatch(addProcessTemplates(processTemplates.processTemplates))
    })
    .catch(error => dispatch(failedProcessTemplates(error.message)));
};

export const loadingProcessTemplates = () => ({
  type: LOADING_PROCESS_TEMPLATES,
});

export const failedProcessTemplates = errmess => ({
  type: FAILED_PROCESS_TEMPLATES,
  payload: errmess,
});

export const addProcessTemplates = processTemplates =>
// {
  // console.log(processTemplates);
  // return
  ({
  type: ADD_PROCESS_TEMPLATES,
  payload: processTemplates,
});
// };
