import {combineReducers} from "redux";
import ProcessTemplateReducer from "./ProcessTemplateReducer";
import ProductionLineReducer from "./ProductionLineReducer";
import MethodologiesReducer from "./MethodologiesReducer";
import AssessmentReducer from "./AssessmentReducer";
import AlertReducer from "./AlertReducer";

export default combineReducers({
  AssessmentReducer,
  ProcessTemplateReducer,
  ProductionLineReducer,
  MethodologiesReducer,
  AlertReducer,
  // QuotaLagoReducer,
  // EsondazioneReducer,
  // LanguageReducer,
  // ScreenReducer,
  // ViewReducer,
});
