import React from "react";
import {connect} from "react-redux";
import AddProcessModalComponent from "../../../components/dialogs/addProcess/AddProcessModalComponent";
import {deleteAssessments, fetchProcessAndAddToProductionLine} from "../../../redux/actions/ProductionLineAction";

const AddProcessModalContainer = props => {
  return <AddProcessModalComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    // processTemplates: state.ProcessTemplateReducer.processTemplates,
    // processTemplates_isLoading: state.ProcessTemplateReducer.isLoading,
    // processTemplates_errMess: state.ProcessTemplateReducer.errMess,
    // language: state.LanguageReducer.language,
    // width: state.ScreenReducer.width,
    // height: state.ScreenReducer.height,
    // view: state.ViewReducer.view,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => (
  {
//   fetchProcessTemplate: () => {
//     dispatch(fetchProcessTemplate());
//   },
    fetchProcessAndAddToProductionLine: (processId) => {
      dispatch(fetchProcessAndAddToProductionLine(processId));
    },
    deleteAssessments: () => {
      dispatch(deleteAssessments())
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProcessModalContainer);
