import _ from 'lodash';

import {
  DELETE_ASSESSMENTS,
  ADD_ASSESSMENTS,
  ADD_PROCESS,
  FAILED_PROCESS,
  LOADING_PROCESS,
  MODIFY_METHODOLOGY,
  MODIFY_PROCESS,
  REMOVE_PROCESS, ADD_PRODUCTION_LINE,
} from "../actions/ProductionLineAction";

const DEFAULT_STATE = {
  isLoading: false,
  errMess: null,
  productionLine:
    {
      assessments: [
        {
          methodologyId: "679a3f0b-79df-4527-af9e-9ff901114715",
          methodologyName: "IMPACT 2002+ (Endpoint)"
        }
      ],
      processes: []
    }
};

export default (state = DEFAULT_STATE, action) => {
  let productionLineClone = _.cloneDeep(state.productionLine);

  switch (action.type) {
    case DELETE_ASSESSMENTS:
      productionLineClone.assessments[0] = DEFAULT_STATE.productionLine.assessments[0]

      productionLineClone.processes.forEach(proc => {
          proc.assessments[0] = DEFAULT_STATE.productionLine.assessments[0]
        }
      )
      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};

    case ADD_ASSESSMENTS:
      //productionLineWAssessments is a short version of productionLine
      let productionLineWAssessments = action.payload
      productionLineClone.assessments[0] = action.payload.assessments[0]

      //update also process assessments
      productionLineClone.processes.forEach(proc => {
          let procWAss = action.payload.processes.find(e => e.id === proc.id)
          proc.assessments[0] = procWAss.assessments[0]
        }
      )
      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};
    case MODIFY_METHODOLOGY:
      let modifiedMethodology = action.payload
      productionLineClone.assessments[0].methodologyId = modifiedMethodology.id
      productionLineClone.assessments[0].methodologyName = modifiedMethodology.name
      productionLineClone.processes.forEach(e => {
          e.assessments[0].methodologyId = modifiedMethodology.id;
          e.assessments[0].methodologyName = modifiedMethodology.name;
        }
      )
      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};
    case MODIFY_PROCESS:
      let modifiedProcess = _.cloneDeep(action.payload)

      //remove flows added in the groups section
      delete modifiedProcess.mixes[0].flows
      let oldProcessIndex = productionLineClone.processes.findIndex(e => e.id === action.payload.id)
      productionLineClone.processes[oldProcessIndex] = modifiedProcess

      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};
    case REMOVE_PROCESS:
      // debugger;
      let processesFiltered = productionLineClone.processes.filter(x => {
        return x.id !== action.payload
      })
      productionLineClone.processes = processesFiltered;
      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};
    case ADD_PRODUCTION_LINE:
      return {...state, isLoading: false, errMess: null, productionLine: action.payload};
    case ADD_PROCESS:
      // debugger;
      productionLineClone.processes.push(action.payload);
      return {...state, isLoading: false, errMess: null, productionLine: productionLineClone};
    case LOADING_PROCESS:
      return {...state, isLoading: true, errMess: null, productionLine: state.productionLine};
    case FAILED_PROCESS:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};