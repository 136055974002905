import React, {useRef, useState} from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import {CSVLink} from "react-csv";

const ImpactsTableComponent = ({data, tableHeaders, productionLine, isProductPage}) => {
  // const [selectedFlowId, setSelectedFlowId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //csv
  const [csvData, setCsvData] = useState([])
  const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  // const getTransactionData = async () => {
  //   // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
  //   await api.post('/api/get_transactions_table', { game_id: gameId })
  //     .then((r) => setTransactionData(r.data))
  //     .catch((e) => console.log(e))
  //   csvLink.current.link.click()
  // }

  const handleGetCSVData = async () => {
    // const data = [
    //   {firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com"},
    //   {firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com"},
    //   {firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com"}
    // ];

    let myData = data.map((e, i) => (
      {
        Methodology: e.methodologyName,
        Category: e.impactCategoryName,
        Indicator: e.impactIndicatorName,
        Value: e.value,
        "Unit of measure": e.unitOfMeasureName,
        // Processo+i :

      }))

    const proc = productionLine.processes.forEach((e, i) => {
        myData.forEach(el => {
            el["Process" + (i + 1)] =
              e.name + "/ " + e.processBaseName + "/ " + e.referenceProductName + "/ " + e.locationAreaName
          }
        )
      }
    )

    // const processData = productionLine.processes.map((e, i) => {
    //   return e.assessments[0].environmentalImpacts.map(el => ({
    //         Methodology: el.methodologyName,
    //         Category: el.impactCategoryName,
    //         Indicator: el.impactIndicatorName,
    //         Value: el.value,
    //         "Unit of measure": el.unitOfMeasureName,
    //     // `String text ${expression}`: `${e.name}/${e.processBaseName}/ " + e.referenceProductName + "/ " + e.locationAreaName`
    //         // el["Process" + (i + 1)] =
    //         //   e.name + "/ " + e.processBaseName + "/ " + e.referenceProductName + "/ " + e.locationAreaName
    //       })
    //     )
    //   }
    // )

    const processData = productionLine.processes.map((e, i) => {
        return e.assessments[0].environmentalImpacts.map(el => {
            const obj = {
              Methodology: el.methodologyName,
              Category: el.impactCategoryName,
              Indicator: el.impactIndicatorName,
              Value: el.value,
              "Unit of measure": el.unitOfMeasureName,
              // `Process ${i+1}`:`${e.name}/ ${e.processBaseName}/ ${e.referenceProductName}/ ${e.locationAreaName}`,
              // el["Process" + (i + 1)] =
              //   e.name + "/ " + e.processBaseName + "/ " + e.referenceProductName + "/ " + e.locationAreaName
            }
            obj["Process" + (i + 1)] = e.name + "/ " + e.processBaseName + "/ " + e.referenceProductName + "/ " + e.locationAreaName
            return obj
          }
        )
      }
    )


    console.log(processData)
    processData.forEach((e,i) => {myData = myData.concat(processData[i])})
    console.log(myData)
    await setCsvData(myData)
    // console.log(csvLink.current)
    csvLink.current.link.click()
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

// data.map(d=>d.quantity).forEach(q=>console.log(q))
  return (
    <Grid container>

      <Grid item xs={12}>
        <TableContainer
          component={isProductPage === true ? Paper : 'div'}
          // component={Paper}
        >
          <Table size="small" aria-label="a dense table">
            <colgroup>
              <col style={{width: '35%'}}/>
              <col style={{width: '35%'}}/>
              <col style={{width: '30%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader, index) => (
                  <TableCell xs={4} key={index}>{tableHeader}</TableCell>))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                  ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : data
              ).map(d => {
                {/*{data.slice((paginationNumber - 1) * 5, (paginationNumber - 1) * 5 + 5).map((d, index) => {*/
                }
                return <TableRow
                  key={d.impactIndicatorId}
                  // style={{
                  //   background: selectedFlowId === d.id ? 'rgba(34,36,38,.1)' : null
                  // }}
                  // onClick={() => setSelectedFlowId(d.id)}
                >
                  <TableCell>{d.impactCategoryName}</TableCell>
                  <TableCell>{d.impactIndicatorName}</TableCell>
                  <TableCell>
                    <div style={{width:'40%', textAlign:'right'}}>{d.value.toExponential(2)} {d.unitOfMeasureName}</div>
                    <div style={{width:'60%'}}></div>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  {isProductPage ?
                    <Button
                      variant="contained"
                      color={"primary"}
                      disabled={productionLine.assessments[0].environmentalImpacts === undefined}
                      onClick={handleGetCSVData}
                    >
                      Export CSV
                    </Button>
                    : null}
                  <CSVLink
                    data={csvData}
                    filename='Impacts.csv'
                    // className='hidden'
                    ref={csvLink}
                    target='_blank'
                  />
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={data.length === 0 ? 1 : data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ImpactsTableComponent;
