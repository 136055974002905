import React from "react";
import {connect} from "react-redux";
import EditProcessModalComponent from "../../../components/dialogs/editProcess/EditProcessModalComponent";
import {deleteAssessments, modifyProcess} from "../../../redux/actions/ProductionLineAction";
import {showAlert} from "../../../redux/actions/AlertAction";

const EditProcessModalContainer = props => {
  return <EditProcessModalComponent {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    productionLine: state.ProductionLineReducer.productionLine,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => (
  {
    modifyProcess: (selectedProcess) => {
      dispatch(modifyProcess(selectedProcess));
    },
    deleteAssessments: () => {
      dispatch(deleteAssessments());
    },
  showAlert: (values) => {
    dispatch(showAlert(values));
  },
}
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProcessModalContainer);
