import React from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import RestoreIcon from '@material-ui/icons/Restore';
import {FlowTypeEnum} from "../../utils/enums/flowTypeEnum";

const FlowsTableComponent = ({
                               tableTitle,
                               data,
                               tableHeaders,
                               handleOnChangeQuantity,
                               handleOnBlurQuantity,
                               handleOpenAddFlowSection,
                               isElementary,
                               isReferenceProduct = false,
                               flowTypeEnum,
                               handleRemoveFlow,
                               handleRestoreFlow,
                               handleRestoreFlowQty,
                               addFlowSectionOpen,
                               changeRPSectionOpen,
                               handleOpenChangeRPSection,
                               createAndChangeRPSectionOpen
                             }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [addBtnLabel, setAddBtnLabel] = React.useState("Add");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // useEffect(() => {
  //   console.log(flowTypeEnum)
  //   console.log(data)
  //   if(addBtnLabel==="Close"){
  //     setAddBtnLabel("Add")
  //   }
  // }, [data]);

  const handleAddFlowOpen = () => {
    if (addFlowSectionOpen !== flowTypeEnum) {
      handleOpenAddFlowSection(flowTypeEnum)
    } else {
      handleOpenAddFlowSection(undefined)
    }
  }

  const handleChangeRPOpen = () => {
    if (changeRPSectionOpen || createAndChangeRPSectionOpen) {
      handleOpenChangeRPSection(false)
    } else {
      handleOpenChangeRPSection(true)
    }
  }

  // data.map(d=>d.quantity).forEach(q=>console.log(q))
  return (
    <Grid container item xs={12} spacing={1}>
      <Typography variant="h6">{tableTitle}</Typography>

      <TableContainer
        // component={Paper}
      >
        <Table size="small" aria-label="a dense table">
          {
            isElementary ?
              <colgroup>
                <col style={{width: '35%'}}/>
                <col style={{width: '25%'}}/>
                <col style={{width: '25%'}}/>
                <col style={{width: '15%'}}/>
              </colgroup>
              :
              flowTypeEnum === FlowTypeEnum.OUTPUT_FLOW ?
                <colgroup>
                  <col style={{width: '40%'}}/>
                  <col style={{width: '30%'}}/>
                  <col style={{width: '30%'}}/>
                </colgroup>
                :
                <colgroup>
                  <col style={{width: '30%'}}/>
                  <col style={{width: '15%'}}/>
                  <col style={{width: '15%'}}/>
                  <col style={{width: '25%'}}/>
                  <col style={{width: '15%'}}/>
                </colgroup>
          }
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader, index) => (
                <TableCell key={index}
                           align={index + 1 === tableHeaders.length || (index + 2 === tableHeaders.length && tableHeaders[index + 1]==='Impacts Contribute') ? "right" : "left"}> {tableHeader} </TableCell>))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
            ).map((d) => {
              {/*{data.slice((paginationNumber - 1) * 5, (paginationNumber - 1) * 5 + 5).map((d, index) => {*/
              }
              return <TableRow
                key={d.id}
                style={{
                  background: d.paretoIncluded ? '#a2cf6e' : d.referenceFlow ? '#ffff8b' : null
                }}
                // onClick={() => setSelectedFlowId(d.id)}
              >
                <TableCell>

                  {d.referenceFlow ?
                    <span>
                      <Box component="span" width={24}
                           style={{display: "inline-block", verticalAlign: "bottom"}}></Box>{d.name}
                    </span>
                    :
                    d.removed ?
                      <span><RestoreIcon style={{verticalAlign: "bottom"}} onClick={(event) => {
                        handleRestoreFlow(d.id, isElementary);
                        event.stopPropagation();
                      }}/><del>{d.name}</del></span>
                      :
                      <span><ClearIcon style={{verticalAlign: "bottom"}} onClick={(event) => {
                        // removeProcess(d.id);
                        handleRemoveFlow(d.id, isElementary);
                        event.stopPropagation();
                      }}/>{d.name}</span>
                  }

                </TableCell>
                {isElementary || flowTypeEnum === FlowTypeEnum.OUTPUT_FLOW ? null :
                  <TableCell>{d.geographyFullName}({d.geographyShortName})</TableCell>}
                <TableCell>{d.flowType}</TableCell>
                <TableCell align="right"> {!d.referenceFlow ? <>
                    <Box component="span" width={24} style={{display: "inline-block", verticalAlign: "bottom"}}>
                      {(d.quantity * d.quantityFactor) !== d.originalQuantity ?
                        <RestoreIcon //style={{verticalAlign: "bottom"}}
                          onClick={(event) => {
                            handleRestoreFlowQty(d.id, isElementary);
                            event.stopPropagation();
                          }}/> : null}
                    </Box>
                    <TextField id="standard-basic"
                               value={d.quantity === "" ? d.quantity : d.quantity * d.quantityFactor}
                               type={"number"}
                               inputProps={{lang: "en"}}
                      // inputProps={{ inputMode: 'numeric', pattern: '-?[0-9]+(\\.[0-9]*)?' }}
                               onBlur={
                                 (e) => {
                                   isElementary ? handleOnBlurQuantity(d.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, d.id, false, isElementary) :
                                     handleOnBlurQuantity(d.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, d.id, d.referenceFlow, isElementary)
                                 }
                               }
                               onChange={
                                 (e) => {
                                   isElementary ? handleOnChangeQuantity(d.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, d.id, false, isElementary) :
                                     handleOnChangeQuantity(d.quantityFactor, e.target.value >= 0 ? 1 : -1, e.target.value, d.id, d.referenceFlow, isElementary)
                                 }
                               }
                    />
                  </> :
                  d.quantityFactor + " "}
                  {d.unitOfMeasureName}</TableCell>
                {
                  flowTypeEnum === FlowTypeEnum.OUTPUT_FLOW ? null :
                    <TableCell align="right">
                    {d.impactsContribute === 0 ? '-' : (d.impactsContribute * 100).toFixed(2) + '%'}
                  </TableCell>
                }
              </TableRow>
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Grid container spacing={1}>
                  {!isReferenceProduct ?
                    <Grid item>
                      <Tooltip title={isElementary ? "Add a new elementary flow" : "Add a new flow"}>
                        <Button variant="contained" color={"primary"} onClick={() => handleAddFlowOpen()}>
                          {addFlowSectionOpen === flowTypeEnum ? "Close" : "Add"}
                        </Button>
                      </Tooltip>
                    </Grid> : null}
                  {isReferenceProduct ?
                    <Grid item>
                      <Tooltip title="Remove actual reference product flow of the process and add the selected one">
                        <Button variant="contained" color={"primary"} onClick={() => handleChangeRPOpen()}>
                          {(changeRPSectionOpen || createAndChangeRPSectionOpen) ? "Close" : "Change RP"}
                        </Button>
                      </Tooltip>
                    </Grid> : null}
                </Grid>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={4}
                count={data.length === 0 ? 1 : data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // SelectProps={{
                //   inputProps: { 'aria-label': 'rows per page' },
                //   native: true,
                // }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default FlowsTableComponent;
